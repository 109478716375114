<mat-toolbar color="primary">
	<mat-toolbar-row class="center-block">
		<div>Додаток відкритий в інщому місці</div>
	</mat-toolbar-row>
</mat-toolbar>

<mat-card class="float-card">
	<mat-card-header></mat-card-header>
	<img mat-card-image src="/assets/images/page-holder-img.jpg" alt="Page holder">
	<mat-card-content>
		<p>
			Робота додатку підтримується тільки в одному екзкмплярі.
		</p>
		<p>
			Якщо ви бачите цю сторінку, це означає, що додаток був відкритий в іншому місці
		</p>
	</mat-card-content>
	<mat-divider></mat-divider>
	<mat-card-actions class="center-block">
		<button mat-flat-button color="primary" routerLink="/dash">
			Перезавантажити сторінку
		</button>
	</mat-card-actions>
</mat-card>
