import { AfterViewInit, Component, OnChanges, OnDestroy, OnInit } from '@angular/core';
import { FormGroupDirective, NgForm, UntypedFormControl, Validators } from '@angular/forms';
import { ErrorStateMatcher } from '@angular/material/core';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { SwUpdate } from '@angular/service-worker';
import { fromEvent, Observable, Subscription } from 'rxjs';

import { environment } from '../../environments/environment';
import { DialogComponent } from '../common/components/dialog/dialog.component';
import { UserModelService } from '../common/models/user-model.service';
import { AuthService } from '../common/services/auth.service';
import { MetaInfoService } from '../common/services/meta-info.service';
import { RequesterService } from '../common/services/requester.service';
import { StorageService } from '../common/services/storage.service';

/** Error when invalid control is dirty, touched, or submitted. */
export class MyErrorStateMatcher implements ErrorStateMatcher {
	isErrorState (control: UntypedFormControl | null, form: FormGroupDirective | NgForm | null): boolean {
		const isSubmitted = form && form.submitted;

		return !!(control && control.invalid && (control.dirty || control.touched || isSubmitted));
	}
}

export interface FIELD_OF_CHECK {
	id: number;
	name: string;
	amount: number;
}

export class CHECK {
	mane: string;
	sum: number;
	tip: number;
	allSum: number;
	fields: Array<FIELD_OF_CHECK>;
	date: number = Date.now();
}

@Component({
	selector: 'app-system',
	templateUrl: './system.component.html',
	styleUrls: ['./system.component.css']
})
export class SystemComponent implements OnInit, OnDestroy {
	title = '';
	showType;
	showPreloader;
	leftMenu;
	rightMenu;

	heartbeat$;
	serverSync$;

	subShowPreloader;
	subError;
	subRightMenu;
	subLeftMenu;

	avatarClicks = 0;
	netError = null;
	showError = false;

	user = { name: '', email: '' };
	showButton: boolean;
	public demoMode = true;
	timer;

	isNoConnect;
	onlineEvent: Observable<Event>;
	offlineEvent: Observable<Event>;
	subscriptions: Array<Subscription> = [];

	emailFormControl = new UntypedFormControl('', [
		Validators.required,
		Validators.email,
	]);
	matcher = new MyErrorStateMatcher();

	constructor (
		private auth: AuthService,
		private router: Router,
		public  metaInfo: MetaInfoService,
		private userModel: UserModelService,
		public  requester: RequesterService,
		public  updates: SwUpdate,
		public  dialog: MatDialog,
		public  storage: StorageService,
	) {

		// Broadcast that you're opening a page.
		localStorage.openpages = Date.now();
		window.addEventListener('storage', e => {
			if (e.key === 'openpages') {
				// alert('One more page already open');
				// Listen if anybody else is opening the same page!
				// localStorage.page_available = Date.now();
				window.location.href = `${window.location.origin}/page-holder`;
			}
		}, false);

		updates.versionUpdates.subscribe(async evt => {
			console.log('event', JSON.stringify(evt));
			// "event,{\"type\":\"VERSION_READY\",\"currentVersion\":{\"hash\":\"7eb36491ff7b73c34845fdec350261f0d937da7a\",\"appData\":\"3.0.0.8\"},\"latestVersion\":{\"hash\":\"53889648c4597645a9e16882398e8cce7785b065\",\"appData\":\"3.0.0.9\"}}
			// "event,{\"type\":\"VERSION_DETECTED\",\"version\":{\"hash\":\"53889648c4597645a9e16882398e8cce7785b065\",\"appData\":\"3.0.0.9\"}}"
			// "event,{\"type\":\"NO_NEW_VERSION_DETECTED\",\"version\":{\"hash\":\"53889648c4597645a9e16882398e8cce7785b065\",\"appData\":\"3.0.0.9\"}}"

			if (evt.type === 'VERSION_READY') {
				const version = evt.currentVersion.appData;
				const newVersion =  evt.latestVersion.appData;
				const dialogRef = this.dialog.open(DialogComponent, {
					width: '450px',
					data: {
						title: 'Доступна нова версія додатку',
						html: `
						поточна версія: ${version}<br>
						нова версія: ${newVersion}<br>
					`,
						rightBtnTitle: 'Оновити',
						leftBtnTitle: 'Пізніше',
					}
				});

				dialogRef.afterClosed().subscribe(result => {
					// console.log('The dialog was closed', result);

					if (result && result.type === 'rightClick') {
						updates.activateUpdate()
							.then(() => {
								document.location.reload();
							});
					}
				});
			}

			if (evt.type === 'VERSION_DETECTED') {
				console.log('detected new version', evt);
			}
		});
	}

	async ngOnInit () {
		this.subShowPreloader = this.metaInfo.showPreloader$.subscribe(value => {
			this.showPreloader = value;
		});

		this.subError = this.metaInfo.error$.subscribe(value => {
			this.netError = value;
		});

		this.subLeftMenu = this.metaInfo.leftMenu$.subscribe(value => {
			this.leftMenu = value;
		});

		this.subRightMenu = this.metaInfo.rightMenu$.subscribe((data: any) => {
			this.rightMenu = data;

			switch (true) {
				case Array.isArray(data) && !!data.length:
					this.showType = 1;
					break;

				case !!data && !!data.href:
					this.showType = 2;
					break;

				default:
					this.showType = 0;
			}
		});

		this.userModel.changeUser()
		.subscribe((data: any) => {

			if (!data) {
				this.user = { name: 'X-user', email: ''};

				return;
			}

			this.demoMode = false;
			this.user     = data;

		}, error => {
			console.log('error subscription ', error);
		});

		this.userModel.getUserFromCache();

		/**
		 * Get the online/offline status from browser window
		 */
		this.onlineEvent = fromEvent(window, 'online');
		this.offlineEvent = fromEvent(window, 'offline');

		this.subscriptions.push(this.onlineEvent.subscribe(e => {
			this.isNoConnect = false;
			console.log('Online...');
		}));

		this.subscriptions.push(this.offlineEvent.subscribe(e => {
			this.isNoConnect = true;
			console.log('Offline...');
		}));

		this.heartbeat$ = this.userModel.heartbeat();
		this.serverSync$ = this.userModel.serverSync();
	}

	ngOnDestroy (): void {
		// Только не забывайте отписаться!
		if (this.subShowPreloader !== null) {
			this.subShowPreloader.unsubscribe();
			this.subShowPreloader = null;
		}
		if (this.subError !== null) {
			this.subError.unsubscribe();
			this.subError = null;
		}
		if (this.subLeftMenu !== null) {
			this.subLeftMenu.unsubscribe();
			this.subLeftMenu = null;
		}
		if (this.subRightMenu !== null) {
			this.subRightMenu.unsubscribe();
			this.subRightMenu = null;
		}

		this.subscriptions.forEach(subscription => subscription.unsubscribe());
	}

	async goLogout (sidenav) {
		sidenav.close();
		await this.auth.logout();
		this.demoMode = true;

		return this.router.navigate(['login']);
	}

	leftMenuClick (sidenav) {

		if (this.leftMenu) {
			return this.router.navigate([this.leftMenu.href], { queryParams: this.leftMenu.queryParams });
		}

		sidenav.open();
	}

	avatarClick () {
		this.timer = window.setTimeout(() => {
			this.avatarClicks = this.avatarClicks > 0 ? this.avatarClicks-- : 0;
		}, 1000);

		if ((this.avatarClicks += 1) >= 5) {
			this.avatarClicks = 0;
			this.showError = !this.showError;
		}
	}
}
