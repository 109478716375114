import { Component } from '@angular/core';

@Component({
	selector: 'app-page-holder',
	templateUrl: './page-holder.component.html',
	styleUrls: ['./page-holder.component.scss']
})
export class PageHolderComponent {

}
