import { Component, OnInit } from '@angular/core';

import { MetaInfoService } from '../../common/services/meta-info.service';

@Component({
	selector: 'app-not-found',
	templateUrl: './not-found.component.html',
	styleUrls: ['./not-found.component.css'],
})
export class NotFoundComponent implements OnInit {
	constructor (
		public metaInfo: MetaInfoService,
	) {
	}

	title = 'Незнайдено';

	ngOnInit () {
		this.metaInfo.setData({
			leftMenu: null,
			rightMenu: null,
			title: this.title,
		});
	}
}
