import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { PageHolderComponent } from './page-holder.component';

const routes: Routes = [
	{ path: 'page-holder', component: PageHolderComponent },
];

@NgModule({
	imports: [RouterModule.forChild(routes)],
	exports: [RouterModule],
})
export class PageHolderRoutingModule {
}
