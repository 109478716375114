import { Component, OnDestroy, OnInit } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

import { UserModelService } from '../../common/models/user-model.service';
import { MetaInfoService } from '../../common/services/meta-info.service';

interface DashData {
	new: number;
	repeat: number;
	inProgress: number;
	done: number;
}

@Component({
	selector: 'app-dash',
	templateUrl: './dash.component.html',
	styleUrls: ['./dash.component.css']
})
export class DashComponent implements OnInit, OnDestroy {
	constructor (
		public metaInfo: MetaInfoService,
		public userModel: UserModelService,
	) { }

	dashData$ = new BehaviorSubject({
		new: 0,
		repeat: 0,
		done: 0
	});
	dashData: DashData;
	_TIMEOUT = 60_000;
	_refreshStarted = false;
	_refreshCounter = 0;

	async ngOnInit (): Promise<void> {

		this.metaInfo.setData({
		leftMenu: null,
		rightMenu: null,
		title: 'Дашборд'
		});

		try {
			this.dashData$.subscribe((data: DashData) => {
				this.dashData = data;
			}, err => {
				this.metaInfo.showError(err);
			});

			this.metaInfo.showPreloader(true);
			this.userModel.getDashData(this.dashData$);
			this.metaInfo.showPreloader(false);

			this._refresh();
		} catch (e) {
			this.metaInfo.showError(e);
		}
	}

	ngOnDestroy () {
		this.dashData$.unsubscribe();
		this._refreshStarted = false;
	}

	_refresh () {
		if (this._refreshStarted) {
			console.log('=-=-=- duoplicate of refresher');

			return;
		}
		console.log('start observer');

		this._refreshStarted = true;

		// checking API access
		const refreshRequest = () => {
			console.log('refresh is planned on ' + new Date(Date.now() + this._TIMEOUT).toISOString());
			window.setTimeout(async () => {
				if (!this._refreshStarted) {
					console.log('refresh stopped');

					return;
				}
				try {
					let localRefresh = (this._refreshCounter += 1) >= 5 ? (this._refreshCounter = 0) : true;

					await this.userModel.getDashData(this.dashData$, !!localRefresh);
					console.log('refresh OK');
				} catch (e) {
					console.log('=-=-refresh ERROR', e);
				}

				refreshRequest();
			}, this._TIMEOUT);
		};

		refreshRequest();
	}
}
