import { CommonModule, NgOptimizedImage } from '@angular/common';
import { NgModule } from '@angular/core';

import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatDividerModule } from '@angular/material/divider';
import { MatIconModule } from '@angular/material/icon';
import { MatMenuModule } from '@angular/material/menu';
import { MatToolbarModule } from '@angular/material/toolbar';
import { PageHolderRoutingModule } from './page-holder-routing.module';
import { PageHolderComponent } from './page-holder.component';

@NgModule({
	declarations: [
		PageHolderComponent
	],
	imports: [
		CommonModule,
		PageHolderRoutingModule,
		MatButtonModule,
		MatCardModule,
		MatDividerModule,
		MatIconModule,
		MatMenuModule,
		MatToolbarModule,
		NgOptimizedImage
	]
})
export class PageHolderModule {
}
