import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { AuthConfirmEmailComponent } from './auth-confirm-email/auth-confirm-email.component';
import { AuthLoginComponent } from './auth-login/auth-login.component';
import { AuthNewPassComponent } from './auth-new-pass/auth-new-pass.component';
import { AuthPasswordResetComponent } from './auth-password-reset/auth-password-reset.component';
import { AuthRegistrationComponent } from './auth-registration/auth-registration.component';
import { DashComponent } from './dash/dash.component';
import { DictionaryComponent } from './dictionary/dictionary.component';
import { ListsAddComponent } from './lists-add/lists-add.component';
import { SetsComponent } from './sets/sets.component';
import { NotFoundComponent } from './not-found/not-found.component';
import { RepeatComponent } from './repeat/repeat.component';
import { StartPageComponent } from './start-page/start-page.component';
import { StudyComponent } from './study/study.component';
import { SystemComponent } from './system.component';
import { TestComponent } from './test/test.component';
import { UnsubscribeComponent } from './unsubscribe/unsubscribe.component';
import { UserInfoComponent } from './user-info/user-info.component';
import { WordAddComponent } from './word-add/word-add.component';
import { WordCardComponent } from './word-card/word-card.component';
import { SetListComponent } from './set-list/set-list.component';

const routes: Routes = [
	// {path: '', redirectTo: '/bills/create', pathMatch: 'full'},

	{
		path: '',
		component: SystemComponent, children: [{
			path: '',
			title: 'Про застосунок',
			component: StartPageComponent
		}, {
			path: 'user-info',
			title: 'Налаштування',
			component: UserInfoComponent
		}, {
			path: 'dictionary',
			title: 'Словник',
			component: DictionaryComponent
		}, {
			path: 'dictionary/:wordId',
			title: 'Словник',
			component: WordCardComponent
		}, {
			path: 'dictionary/:wordText/edit',
			title: 'Редагування слова',
			component: WordAddComponent
		}, {
			path: 'repeat',
			title: 'Повторення слів',
			component: RepeatComponent
		}, {
			path: 'study',
			title: 'Вивчення слів',
			component: StudyComponent
		}, {
			path: 'dash',
			title: 'Дашборд',
			component: DashComponent
		}, {
			path: 'add', title: 'Додавання слів', component: WordAddComponent
		}, {
			path: 'sets', title: 'Списки слів', component: SetsComponent
		}, {
			path: 'sets/:listId', title: 'Списки слів', component: SetListComponent
		}, {
			path: 'sets/:listName/word/:word', title: 'Слово', component: ListsAddComponent
		}, {
			path: 'sets/:listName/word/:wordText/edit', title: 'Додавання слова', component: WordAddComponent
		}, {
			path: 'registration', title: 'Реєстрація', component: AuthRegistrationComponent
		}, {
			path: 'login', title: 'Логін', component: AuthLoginComponent
		}, {
			path: 'reset-pass', title: 'Зкидання паролю', component: AuthPasswordResetComponent
		}, {
			path: 'new-pass/:code', title: 'Новий пароль', component: AuthNewPassComponent
		}, {
			path: 'confirm/:salt', title: 'Новий пароль', component: AuthConfirmEmailComponent
		}, {
			path: 'unsubscribe', component: UnsubscribeComponent
		}, {
			path: 'not-found', title: 'Даної сторінки не існує', component: NotFoundComponent
		}, {
			path: '_test',
			title: 'Тестова сторінка',
			component: TestComponent
		}, {
			path: '**', title: 'Даної сторінки не існує', component: NotFoundComponent
		}
		]
	}
];

@NgModule({
	imports: [RouterModule.forRoot(routes, {})],
	exports: [RouterModule]
})
export class SystemRoutingModule {
}
