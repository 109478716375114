import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { SwPush } from '@angular/service-worker';

import { DialogComponent } from '../../common/components/dialog/dialog.component';
import { UserModelService } from '../../common/models/user-model.service';
import { AuthService } from '../../common/services/auth.service';
import { MetaInfoService } from '../../common/services/meta-info.service';
import { RequesterService } from '../../common/services/requester.service';
import { SpeakerService } from '../../common/services/speaker.service';
import { StorageService } from '../../common/services/storage.service';

@Component({
	selector: 'test',
	templateUrl: './test.component.html',
	styleUrls: ['./test.component.css'],
})
export class TestComponent implements OnInit  {

	time = '100';

	constructor (
		private router: Router,
		private requester: RequesterService,
		private swPush: SwPush,
		private auth: AuthService,
		public  dialog: MatDialog,
		public  userModel: UserModelService,
		public  metaInfo: MetaInfoService,
		public  speaker: SpeakerService,
		public  storage: StorageService,
	) {
	}

	async ngOnInit () {


	}

	searchButton () {
		console.log(JSON.parse(this.time));

		window.navigator.vibrate && window.navigator.vibrate(JSON.parse(this.time));
	}
}
