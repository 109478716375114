<mat-card class="float-card text-center" *ngIf="!!user">
	<mat-icon class="user-photo">account_circle</mat-icon>
	<mat-card-title class="center-icon">
		{{ user.name }}
		<button mat-icon-button (click)="editUser()">
			<mat-icon>mode_edit</mat-icon>
		</button>
	</mat-card-title>
	<mat-card-subtitle>
		email: {{ user.email }}
	</mat-card-subtitle>
	<mat-list>
		<mat-list-item class="border">
			<mat-icon matListItemIcon>email</mat-icon>
			<div matListItemTitle>Ел. пошта: <b>{{ user.email }}</b></div>
			<!--<mat-icon click="editUser(user.id)">mode_edit</mat-icon>-->
		</mat-list-item>
		<mat-list-item class="border">
			<mat-icon matListItemIcon>language</mat-icon>
			<div matListItemTitle>Мова: <b>{{ user.lang }}</b></div>
			<!--<mat-icon click="editUser(user.id)">mode_edit</mat-icon>-->
		</mat-list-item>
		<mat-list-item class="border">
			<mat-icon matListItemIcon>record_voice_over</mat-icon>
			<div matListItemTitle class="combo-bloc">
				<div>Голос:</div>
				<mat-form-field appearance="outline">
					<mat-label>State</mat-label>
					<mat-select [(value)]="selectedVoice" (selectionChange)="changeVoice()">
						<mat-option>None</mat-option>
						<mat-option *ngFor="let voice of listVoices" [value]="voice.name">{{voice.name}} {{voice.lang}}</mat-option>
					</mat-select>
<!--					<mat-hint>here</mat-hint>-->
				</mat-form-field>
			</div>
			<!--<mat-icon click="editUser(user.id)">mode_edit</mat-icon>-->
		</mat-list-item>
		<mat-list-item class="border" matTooltip="Незабаром" matTooltipPosition="above">
			<mat-icon matListItemIcon>notifications</mat-icon>
			<div matListItemTitle class="divided-list-item">Нотифікація:
				<mat-slide-toggle
					class="example-margin"
					(toggleChange)="toggleNotification()"
					[color]="color"
					[ngModel]="notification"
					[disabled]="not_disabled">
				</mat-slide-toggle>
			</div>

		</mat-list-item>
<!--		<mat-list-item class="border">-->
<!--			<mat-icon mat-list-icon>location_on</mat-icon>-->
<!--			<h4 mat-line>Местоположение:</h4>-->
<!--			<mat-slide-toggle-->
<!--				class="example-margin"-->
<!--				(toggleChange)="locationTogle()"-->
<!--				[color]="color"-->
<!--				[ngModel]="location"-->
<!--				[disabled]="loc_disabled">-->
<!--			</mat-slide-toggle>-->
<!--		</mat-list-item>-->
		<mat-list-item>
			<p class="button-row first-row" style="width: 100%; text-align: right;">
				<button mat-raised-button (click)="logout()" color="primary">Вийти</button>
			</p>
		</mat-list-item>
	</mat-list>
</mat-card>
