import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router, UrlSerializer } from '@angular/router';
import { filter } from 'rxjs/operators';

import { DialogComponent } from '../../common/components/dialog/dialog.component';
import { UserModelService } from '../../common/models/user-model.service';
import { MetaInfoService } from '../../common/services/meta-info.service';
import { RequesterService } from '../../common/services/requester.service';
import { SpeakerService } from '../../common/services/speaker.service';

@Component({
	selector: 'app-dash',
	templateUrl: './word-add.component.html',
	styleUrls: ['./word-add.component.css']
})
export class WordAddComponent implements OnInit {
	constructor (
		public metaInfo: MetaInfoService,
		public requester: RequesterService,
		private userModel: UserModelService,
		private dialog: MatDialog,
		private router: Router,
		private currentRout: ActivatedRoute,
		private speaker: SpeakerService,
		private urlSerializer: UrlSerializer,
	) {
	}

	disabledInput = true;
	searchClicked = false;
	queryParam = '';
	searchField = '';
	translate = '';
	userTranslate = '';
	userTranscription = '';
	userExample = {
		en: '',
		org: ''
	};

	foundWord;
	wordText;
	backToUrl;
	backToQp;

	ngOnInit (): void {
		this.searchField = this.currentRout.snapshot.queryParamMap.get('search')
			?.replace(/^\W+/, '')
			.replace(/\W+$/, '').trim();
		this.wordText    = this.currentRout.snapshot.params.wordText;

		let backToRaw = this.currentRout.snapshot.queryParamMap.get('backto');

		if (backToRaw) {
			let backToTree = this.urlSerializer.parse(backToRaw);
			this.backToUrl = backToRaw.split('?')[0];
			this.backToQp  = backToTree.queryParams;
		}

		if (this.wordText) {
			this.searchWord(this.wordText);

			this.metaInfo.setData({
				leftMenu: {
					icon: 'arrow_back',
					href: this.backToUrl,
					queryParams: this.backToQp
				},
				title: 'Редагування слова'
			});
		} else {
			this.metaInfo.setData({
				rightMenu: { },
				title: 'Додавання слів'
			});
			this.currentRout.queryParams.pipe(filter(params => params.search))
				.subscribe(params => {
					if (params.search) {
						this.queryParam = params.search.replace(/^\W+/, '')
							.replace(/\W+$/, '').trim();
						this.searchWord(this.queryParam);
					}
				});
		}
	}

	async searchButton () {
		if (this.disabledInput) {
			return;
		}

		let searchedWord = this.searchField = this.searchField
			.replace(/^\W+/, '')
			.replace(/\W+$/, '').trim();

		if (this.queryParam.toLowerCase().trim() === searchedWord) {
			return;
		}

		this.cleanForm(true);

		return this.router.navigate(
			['add'],
			{ queryParams: { search: searchedWord } }
		);
	}

	async searchWord (word) {
		this.searchClicked = true;
		try {
			this.metaInfo.showPreloader(true);
			let data = await this.userModel.searchWord(word);
			this.foundWord = this.convert(data);
			this.metaInfo.showPreloader(false);
		} catch (e) {
			this.metaInfo.showError(e);
		}
	}

	convert (data) {
		if ((data?.transcription || '').includes('|')) {
			data.transcription = data.transcription.split('|')[0];
		}

		if (Array.isArray(data.translate)) {
			if (data.translate?.length) {
				data.translate.sort((a, b) => b.s - a.s);

				data.translate[0].active = true;
				this.translate = data.translate[0].v;
			} else {
				data.translate = [];
			}
		} else if (data.translate) {
			this.translate = data.translate;
			data.translate = [{ v: this.translate, active: true }];
		} else {
			data.translate = [];
		}

		if (!data?.examples) {
			data.examples = [];
		}
		this.userModel.sortExamples([data]);

		data.examples = data.examples.map((example, i) => {
			if (i >= 5) {
				example.notUse = true;
			}

			return example;
		});

		data.localSaved = !!data.localSaved;

		return data;
	}

	exampleClick (example) {
		example.notUse = !example.notUse;
	}

	translateClick (index) {
		this.foundWord.translate = this.foundWord.translate.map((item, i) => {
			if (index === i) {
				item.active = true;
				this.translate = item.v;
				this.userTranslate = null;
			} else {
				item.active = false;
			}

			return item;
		});
	}

	checkUserTranslate () {
		if (!this.userTranslate) {
			return;
		}

		this.translate = '';
		this.translateClick(null);
	}

	addExample () {
		let newExample = {
			en: '',
			org: '',
			custom: true,
		};

		this.userExample.en = '';
		this.userExample.org = '';

		const dialogRef = this.dialog.open(DialogComponent, {
			width: '450px',
			data: {
				title: 'Додавання прикладу',
				example: newExample,
				rightBtnTitle: 'Зберегти',
				rightBtnColor: 'accent',
				leftBtnTitle: 'Відміна',
				leftBtnColor: 'basic',
			}
		});

		dialogRef.afterClosed().subscribe(async result => {
			if (result && result.type === 'rightClick') {
				if (newExample.en || newExample.org) {
					this.foundWord.examples.push(newExample);
				}
			}
		});
	}

	async saveAsNew () {
		if (!this.canSave()) {
			return;
		}

		try {
			const data = this.collectData();
			this.metaInfo.showPreloader(true);
			await this.userModel.saveLocalAndSendWord(data);
			this.metaInfo.showPreloader(false);
			this.cleanForm();
			if (this.backToUrl) {
				return this.router.navigate([this.backToUrl], { queryParams: this.backToQp });
			}

			return this.router.navigate(['add']);
		} catch (e) {
			if (e.name === 'HttpErrorResponse') {
				if (e?.error?.data?.error?.details?.substatus === 2) {
					this.metaInfo.showPreloader(false);

					const dialogRef = this.dialog.open(DialogComponent, {
						width: '450px',
						data: {
							title: 'Таке слово вже є в словнику',
							desc: 'Спробуйте додати інше',
							rightBtnTitle: 'Ok',
						}
					});

					return;
				}
			}

			this.metaInfo.showError(e);
		}
	}

	async saveAsKnown () {
		if (this.wordText) {
			return this.router.navigate([this.backToUrl], { queryParams: this.backToQp });
		}

		if (!this.canSave()) {
			return;
		}

		try {
			const data = this.collectData(3);
			this.metaInfo.showPreloader(true);
			await this.userModel.saveLocalAndSendWord(data);
			this.metaInfo.showPreloader(false);
			this.cleanForm();
			if (this.backToUrl) {
				return this.router.navigate([this.backToUrl], { queryParams: this.backToQp });
			}

			return this.router.navigate(['add']);
		} catch (e) {
			this.metaInfo.showError(e);
		}
	}

	collectData (status?: number) {
		const data = {
			id: this.foundWord.wordId,
			text: this.foundWord.text.toLowerCase(),
			transcription: (this.userTranscription || ((Array.isArray(this.foundWord.transcription) && this.foundWord.transcription.length > 1)
				? this.foundWord.transcription.map(w => (w || '').trim())[0]
				: this.foundWord.transcription)).replace(/\//ig, '').trim(),
			translate: (this.userTranslate || this.translate).toLowerCase(),
			allExamples: this.foundWord.examples,
			examples: this.foundWord.examples.filter(ex => !ex.notUse),
			part_of_speech: this.foundWord.part_of_speech,
			status: status ? status : this.foundWord.status,
			version: this.foundWord.version,
			tx: this.foundWord.translate || undefined
		};

		return data;
	}

	cleanForm (skipSearch = false) {
		this.searchClicked = false;
		this.foundWord = null;
		this.userExample.en = '';
		this.userExample.org = '';
		this.userTranslate = '';
		this.userTranscription = '';
		this.queryParam = '';
		if (!skipSearch) {
			this.searchField = '';
		}
	}

	canSearch () {
		this.disabledInput = this.isCyrillicLater(this.searchField) || !(this.searchField?.length > 1);
	}

	isCyrillicLater (text) {
		return /[\u0401\u0451\u0410-\u044fєїіґ]/ig.test(text);
	}

	isReadyToSave () {
		return (this.foundWord.transcription || (this.userTranscription && !this.isCyrillicLater(this.userTranscription)))
			&& (this.userTranslate || this.translate);
	}

	canSave () {
		const data = this.collectData();

		return data.translate && data.text && data.transcription;
	}

	saySmt (text) {
		this.speaker.saySmt(text);
	}

	changeExample (index) {
		const original = {
			en:  this.foundWord.examples[index].en,
			org: this.foundWord.examples[index].org,
		};

		const dialogRef = this.dialog.open(DialogComponent, {
			width: '90%',
			data: {
				title: 'Редагування прикладу',
				example: this.foundWord.examples[index],
				rightBtnTitle: 'Зберегти',
				rightBtnColor: 'accent',
				leftBtnTitle: 'Відміна',
				leftBtnColor: 'basic',
			}
		});

		dialogRef.afterClosed().subscribe(async result => {
			if (result && result.type === 'rightClick') {
				this.foundWord.examples[index].notUse = false;
				if (!this.foundWord.examples[index]?.en && !this.foundWord.examples[index]?.org) {
					this.foundWord.examples.splice(index, 1);
				}
			} else {
				this.foundWord.examples[index].en = original.en;
				this.foundWord.examples[index].org = original.org;
			}
		});
	}
}
