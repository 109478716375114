<mat-card *ngIf="!_finish && words && !words.length" class="float-card main-card">
	<mat-card-content>
		<mat-card-header class="title-card">
			<mat-card-title class="large-text">Немає слів для довання</mat-card-title>
			<!--				<mat-card-subtitle> </mat-card-subtitle>-->
		</mat-card-header>
		<mat-divider></mat-divider>
		<div class="text-center" style="margin: 16px;">
			<mat-icon class="big-icon">task_alt</mat-icon>
		</div>
	</mat-card-content>
	<mat-card-actions class="center-block">
		<button routerLink="/dash" mat-stroked-button>Головна</button>
		<button routerLink="/study" mat-stroked-button>Вчити слова</button>
	</mat-card-actions>
	<mat-card-footer>
		<!--		<mat-progress-bar mode="indeterminate"></mat-progress-bar>-->
	</mat-card-footer>
</mat-card>
<mat-card *ngIf="!_finish && words && words.length" class="float-card main-card">
	<mat-card-content>
		<div class="card center" (click)="flipCard()" [ngClass]="flip ?  'flip-card' : 'card'">
			<div class="front inner-card large-text">
				{{words[index].text}}
				<mat-icon *ngIf="words[index].status" class="bookmark-left" matTooltip="Слово вже є в словнику">bookmark</mat-icon>
				<!--		<img src="https://material.angular.io/assets/img/examples/shiba2.jpg" alt="">-->
			</div>
			<div class="back inner-card">
				<div style="margin: 16px; height: 100%">
					<mat-card-header class="title-card text-center">
						<mat-card-title class="large-text">{{words[index].translate}}</mat-card-title>
						<mat-card-subtitle>/{{words[index].transcription}}/</mat-card-subtitle>
					</mat-card-header>
					<div class="examples">
						<p *ngFor="let example of words[index].examples">
							➦ {{example.en}}<br>
							➥ {{example.org}}
						</p>
					</div>
				</div>
			</div>
		</div>
		<!--				<mat-divider></mat-divider>-->
	</mat-card-content>
	<mat-card-actions class="center-block">
		<button mat-stroked-button *ngIf="!words[index].status" (click)="saveAsKnown()">⬅️ Вже знаю</button>
		<button mat-stroked-button *ngIf="!words[index].status" (click)="saveToLearn()">На вивчення ➡️</button>
		<button *ngIf="!!words[index].status" [routerLink]="['/dictionary', words[index].text]" [queryParams]="backTo" mat-raised-button color="primary">
			Перейти до словнику
		</button>
	</mat-card-actions>
	<mat-card-footer>
		<!--		<mat-progress-bar mode="indeterminate"></mat-progress-bar>-->
	</mat-card-footer>
</mat-card>

<mat-card *ngIf="_finish" class="float-card main-card">
	<mat-card-content>
		<mat-card-header class="title-card">
			<mat-card-title class="large-text">Додавання завершене</mat-card-title>
			<!--			<mat-card-subtitle>/{{words[index].transcription}}/</mat-card-subtitle>-->
		</mat-card-header>
		<mat-divider></mat-divider>
		<div>

		</div>
		<mat-divider></mat-divider>
	</mat-card-content>
	<mat-card-actions class="center-block">
		<button mat-stroked-button routerLink="/dash">Головна</button>
		<button (click)="getWords()" mat-stroked-button>Додати ще</button>
	</mat-card-actions>
	<mat-card-footer>
		<!--		<mat-progress-bar mode="indeterminate"></mat-progress-bar>-->
	</mat-card-footer>
</mat-card>

<mat-card *ngIf="offline" class="float-card main-card">
	<mat-card-content>
		<mat-card-header class="title-card">
			<mat-card-title class="large-text">Даний функціонал працює тільки в режімі онлайн</mat-card-title>
		</mat-card-header>
		<mat-divider></mat-divider>
		<div class="text-center" style="margin: 16px;">
			<mat-icon class="big-icon">wifi_off</mat-icon>
		</div>
	</mat-card-content>
	<mat-divider></mat-divider>
	<mat-card-actions class="center-block">
		<button mat-stroked-button routerLink="/dash">Головна</button>
	</mat-card-actions>
	<mat-card-footer>
		<!--		<mat-progress-bar mode="indeterminate"></mat-progress-bar>-->
	</mat-card-footer>
</mat-card>
