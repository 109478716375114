import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { DateTime } from 'luxon';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { LocalFakeError } from '../Error';
import { db, getNextCheckTime, Word } from '../services/db';
import { MetaInfoService } from '../services/meta-info.service';
import { RequesterService } from '../services/requester.service';
import { StorageService } from '../services/storage.service';

@Injectable({
	providedIn: 'root',
})
export class UserModelService {

	id;
	name;
	email;
	lang = '';
	sub$ = new Subject();

	test = '';

	queue = [];
	_isOnline$ = new BehaviorSubject(true);
	_isServerSync$ = new BehaviorSubject(0);
	_isSyncingQueue$ = new BehaviorSubject(false);
	_heartbeatStarted = false;
	_canResendStoredRequests = true;

	constructor (
		private requester: RequesterService,
		private router: Router,
		private storage: StorageService,
		public metaInfo: MetaInfoService,
	) {
	}

	async createUser ({ id, name, email, lang }) {
		this.test = 'start user getting';

		await this.storage.set('user', { id, name, email, lang });

		this.id = id;
		this.name = name;
		this.email = email;
		this.lang = lang;

		this.sub$.next({
			id: this.id,
			name: this.name,
			email: this.email,
			lang: this.lang,
		});

		// todo it can call recursion! try restore user saved requests
		// await this.restoreSavedRequests();

		return {
			id: this.id,
			name: this.name,
			email: this.email,
			lang: this.lang,
		};
	}

	updateUser ({ name }) {
		return this.requester.updateUser({ name })
		.then(() => {
			return this.getStoredUser();
		})
		.then(user => {
			user.name = name;

			return this.createUser(user);
		});
	}

	changeUser () {
		return this.sub$;
	}

	getUser () {

		return this.storage.get('user')
		.then(user => {
			return this.createUser(user);
		})
		.catch(() => {

			return this.requester.getUser()
			.then(user => {

				return this.createUser(user);
			})
			.catch(err => {
				if (err && err.code === 'NEED_AUTH') {
					this.router.navigate(['login']);
				}
			});
		});
	}

	async getUserFromCache () {
		try {
			let user = await this.storage.get('user');

			// todo df does`nt have user
			if (user) {
				this.sub$.next({
					name: user.name,
					email: user.email,
				});
			}

			return user;
		} catch (err) {
			console.log('get user', err);
		}
	}

	deleteUser () {
		this.name = null;
		this.email = null;

		return this.storage.del('user')
		.then(() => {

			this.sub$.next(null);
		});
	}

	getStoredUser (): Promise<{ id: number, name: string, email: string, lang: string }> {
		return this.storage.get('user');
	}

	sortExamples (words: Array<Word>) {
		if (!Array.isArray(words)) {
			throw new Error('Wrong argument: words, should be an array');
		}

		words.forEach(word => {
			if (Array.isArray(word?.examples)) {
				word.examples.sort((a, b) => {
					return ((a?.en?.length || 1000) + (a?.org?.length || 1000) + (a?.s ? 1_000_000 - (a.s * 1000) : 1_000_000))
						- ((b?.en?.length || 1000) + (b?.org?.length || 1000) + (b?.s ? 1_000_000 - (b.s * 1000) : 1_000_000));
				});
			}
		});
	}

	async getLang () {
		if (!this.lang) {
			await this.getUser();
		}

		return this.lang;
	}

	async getUserId (): Promise<number> {
		if (!this.id) {
			await this.getUser();
		}

		return this.id;
	}

	async saveLocalAndSendWord (data) {
		if (!data.id) {
			try {
				await db.words.add({
					text: data.text,
					transcription: data.transcription,
					translate: data.translate,
					examples: data.examples,
					status: data.status
				});

				return this.safeRequest('saveWord', {
					text: data.text,
					transcription: data.transcription,
					translate: data.translate,
					examples: data.allExamples,
					status: data.status,
					tx: data.tx,
					draft: data.draft,
				});
			} catch (e) {
				if (e.name !== 'ConstraintError') {
					throw e;
				}
			}
		}

		await db.words.where('text').equals(data.text).modify({
			text: data.text,
			transcription: data.transcription,
			translate: data.translate,
			examples: data.examples,
			status: data.status
		});

		return this.safeRequest('updateWord', {
			id: data.id,
			text: data.text,
			transcription: data.transcription,
			translate: data.translate,
			examples: data.allExamples,
			status: data.status,
			version: data.version,
			tx: data.tx,
			draft: data.draft,
		});
	}

	async saveWord (data) {
		const result = await this.requester.saveUserWord(await this.getUserId(), data);
		await db.words.where('text').equals(result.text).modify({
			wordId: result.id,
			version: result.version,
			versionMod: result.version
		});
	}

	async searchInDictionary (word) {
		return db.words.where('text').startsWithIgnoreCase(word).limit(25).toArray();
	}

	async searchWord (word) {
		const localWord = await db.words.where('text').equals(word).first();

		if (localWord) {
			localWord.localSaved = true;
			this.sortExamples([localWord]);

			return localWord;
		}

		try {
			return await this.requester.searchWord(word, await this.getLang());
		} catch (err) {
			console.log('error: word seek', err?.message);
		}

		return { text: word };
	}

	async updateWord (data) {
		return this.requester.updateUserWord(await this.getUserId(), data.text, data);
	}

	async markAsKnownLocalWord (word) {
		await db.words.where('text').equals(word.text).modify({
			status: 3,
			step: 0
		});

		return this.safeRequest('markAsKnownWord', {
			id: word.wordId,
			text: word.text,
			version: word.version,
		});
	}

	async markAsKnownWord (word) {
		return this.requester.userWordMarkAsKnown(await this.getUserId(), {
			id: word.id,
			text: word.text,
			version: word.version,
		});
	}

	async getDashData (subject, onlyLocal?: boolean) {
		const localData = await this.getDashLocalWords();
		subject.next(localData);

		if (onlyLocal) {
			return;
		}

		const resNew = await this.syncAll(localData);
		subject.next({ ...localData, ...resNew });
	}

	async getDashLocalWords () {
		const newWords = await db.words.where('status').equals(1).count();
		const allRepeatWords = await db.words.where('status').equals(2);
		const allRepeat = await allRepeatWords.count();
		const repeatNowWords = await allRepeatWords.filter(word => {
			return word.next_check_at < Date.now();
		}).count();
		const doneWords = await db.words.where('status').equals(3).count();

		return {
			new: newWords,
			repeat: repeatNowWords,
			inProgress: allRepeat,
			done: doneWords + newWords + allRepeat
		};
	}

	async getLocalWords (page: number = 0, sort: {
		field?: string,
		order?: string,
		filteredBy?: string
	} = { field: 'added', order: 'desc'}) {
		// console.log('get next page', page, JSON.stringify(sort));

		let collection;

		if (sort.filteredBy) {
			switch (sort.filteredBy) {
				case 'new':
					collection = db.words.where('status').equals(1);
					break;
				case 'repeat':
					collection = db.words.where('status').equals(2);
					break;
				case 'done':
					collection = db.words.where('status').equals(3);
					break;
				default:
					collection = db.words.toCollection();
			}
		} else {
			collection = sort.field === 'added'
				? db.words.orderBy('added_at')
				: db.words.orderBy('updated_at');

			if (sort.order !== 'asc') {
				collection.reverse();
			}
		}

		return collection.offset(page * 25).limit(25).toArray();
	}

	async getWords (userId, page: number = 0) {
		const words = await this.requester.getUserWords(userId, page);

		return words.map(w => this.convertUserWord(w));
	}

	async delLocalWord (word: string) {
		const deleteCount = await db.words.where('text').equals(word).delete();
		console.log('Deleted word:' + word + '; count:' + deleteCount);

		return true;
	}

	async safeDelWord (word) {
		await this.delLocalWord(word.text);

		return this.safeRequest('delWord', word);
	}

	async delWord (word: string) {
		return this.requester.delUserWordByText(await this.getUserId(), word);
	}

	async resetLocalWordAndSend (word) {
		await db.words.where('text').equals(word.text)
			.modify(value => {
				value.status = 1;
				value.step = 0;
			});

		return this.safeRequest('resetWord', word);
	}

	async resetWord (word) {
		return this.requester.resetUserWord(await this.getUserId(), word);
	}

	async getLocalWordByText (wordText: string) {
		const word = await db.words.where('text').equals(wordText).first();
		this.sortExamples([word]);

		return word;
	}

	async getWordByText (wordText: string) {
		const word = await this.requester.getWord(wordText, await this.getLang());

		const convertedWord = this.convertCommonWord(word);

		this.sortExamples([convertedWord]);

		return convertedWord;
	}

	async getLocalStudyWords (): Promise<any> {
		const newWords = await db.words.where('status').equals(1).limit(100).sortBy('updated_at');
		if (newWords.length < 5) {
			throw new LocalFakeError(400, { subcode: 1, count: newWords.length });
		}

		const studyWord = newWords.slice(0, 5);

		this.sortExamples(studyWord);

		return studyWord.map(word => this.convertWordToStudy(word));
	}

	convertWordToStudy (word) {
		return {
			wordId: word.wordId,
			text: word.text,
			transcription: word.transcription.split('|')[0],
			translate: word.translate,
			examples: word.examples,
			status: word.status,
			version: word.version,
		};
	}

	async getLocalRepeatWords (): Promise<any> {
		const newWords = await db.words.where('status').equals(2)
			.and(word => word.next_check_at < Date.now()).limit(100).toArray();

		const studyWord = [];

		const length = Math.min(5, newWords.length);
		for (let i = 0; i < length; i++) {
			let i = Math.floor(Math.random() * newWords.length);
			studyWord.push(newWords.splice(i, 1)[0]);
		}

		this.sortExamples(studyWord);

		return studyWord;
	}

	async saveLocalStudiedWords (words): Promise<any> {
		const localWords = db.words.where('text').anyOf(words.map(w => w.text));
		const nextCheck = getNextCheckTime(1);
		localWords.modify({
			status: 2,
			step: 1,
			next_check_at: nextCheck
		});

		const filteredWords = words.map(word => {
			return {
				id: word.wordId,
				text: word.text,
				version: word.version,
				nextCheck
			};
		});

		return this.safeRequest('saveStudiedWords', filteredWords);
	}

	async saveLocalFailedWords (words): Promise<any> {
		const localWords = db.words.where('text').anyOf(words.map(w => w.text));

		// hack to prevent version updating and update  'updated_at'' property
		await localWords.modify({
			version: null
		});
	}

	async saveStudiedWords (studiedWords) {
		return this.requester.saveUserStudyWords(await this.getUserId(), { words: studiedWords });
	}

	async saveLocalRepeatWords ({ reset, done }): Promise<any> {
		const resetWords = [];
		const resetTime = getNextCheckTime(0);
		await db.words.where('text').anyOf(reset).modify(value => {
			resetWords.push({
				id: value.wordId,
				text: value.text,
				version: value.version,
				nextCheck: resetTime,
			});

			if (value.step < 1) {
				value.step = 0;
				value.status = 1;
			} else {
				value.step = value.step === 1 ? 0 : 1;
			}
			value.next_check_at = resetTime;
		});

		const doneWords = [];
		await db.words.where('text').anyOf(done).modify(value => {
			const data = {
				id: value.wordId,
				text: value.text,
				version: value.version,
			};

			if (value.step >= 10) {
				value.status = 3;
			} else {
				value.step = value.step + 1;
				const nextCheck = getNextCheckTime(value.step);
				data['nextCheck'] = nextCheck;
				value.next_check_at = nextCheck;
			}

			doneWords.push(data);
		});

		return this.safeRequest('saveRepeatWords', { resetWords, doneWords });
	}

	async saveRepeatWords ({ resetWords, doneWords }) {
		return this.requester.saveUserRepeatWords(await this.getUserId(), { reset: resetWords, done: doneWords });
	}

	async getUserWordsLists (ignoreSW?): Promise<any> {
		return this.requester.getWordsLists(await this.getUserId(), ignoreSW);
	}

	async getUserWordListsById (listId: number, ignoreSW = false): Promise<any> {
		return this.requester.getWordsListById(await this.getUserId(), listId, await this.getLang(), ignoreSW);
	}

	async getUserWordListByName (listId: number, page = 0, ignoreSW = false): Promise<any> {
		return this.requester.getWordListByName(await this.getUserId(), listId, await this.getLang(), page, ignoreSW);
	}

	async syncAll (localData) {
		let newData;
		let isResent;

		if (this.isOnSync()) {
			return localData;
		}

		try {
			let nr = await this.storedRequestsLength();

			this._isOnline$.next(true);

			if (nr) {
				this._isServerSync$.next(100);

				console.log('=-=-=resend requests: started');
				isResent = await this.resendStoredRequests();
				console.log('=-=-=resend requests: finished');
			} else {
				isResent = true;
				console.log('=-=-=resend requests: NOTHING');
			}

			newData = await this.requester.getDashData(await this.getUserId(), true);
		} catch (err) {
			console.log('=-=-=resend requests: error');
			if (err['code'] === 'NEED_AUTH') {
				return this.router.navigate(['login']);
			}
			this._isServerSync$.next(0);
			this._isOnline$.next(false);

			return localData;
		}

		console.log('serverData', JSON.stringify(newData));
		console.log('localData', JSON.stringify(localData));
		if (!newData || !isResent) {
			this._isServerSync$.next(0);

			return localData;
		}

		if (newData.done === localData.done && newData.new === localData.new && newData.repeat === localData.repeat) {
			this._isServerSync$.next(0);

			return newData;
		}

		const SYNC_PERCENTS = 90;
		this._isServerSync$.next(SYNC_PERCENTS);

		const userId = await this.getUserId();
		const wordNumber = newData.done;
		let wordCounter = 0;
		let page = 0;
		const firstSync = await this.isFirstSync();

		if (firstSync) {
			console.log('first sync');
			let syncPage = 0;
			let wordsResult: Array<null | Word> = [null];

			while (wordsResult.length) {
				wordsResult = await this.getWords(userId, syncPage++);

				console.log('received words sync; length:', wordsResult.length);

				for (let word of wordsResult) {
					this._isServerSync$.next((wordNumber - (wordCounter += 1)) * SYNC_PERCENTS / wordNumber);

					try {
						await db.words.add({
							wordId: word.wordId,
							text: word.text,
							transcription: word.transcription,
							translate: word.translate,
							examples: word.examples,
							status: word.status,
							step: word.step,
							version: word.version,
							added_at: word.added_at,
							updated_at: word.updated_at,
							next_check_at: word.next_check_at,
						});
					} catch (e) {
						if (e.name !== 'ConstraintError') {
							console.log('error while sync', e);
						}
					}
				}

				console.log('received queue, adding');
			}

			this._isServerSync$.next(0);

			return newData;
		}

		let syncList = await this.getSyncList(userId, page, true);
		let localWordsToDelete = [];
		let checkAllWords = false;

		let waiter = async () => {
			let newLokalWords;

			if (this._isSyncingQueue$.getValue() === false) {
				newLokalWords = await this.getDashLocalWords();
			} else {
				await new Promise(res => {
					this._isSyncingQueue$.subscribe(async newState => {
						if (newState === false) {
							newLokalWords = await this.getDashLocalWords();

							res(true);
						}
					});
				});
			}

			return newLokalWords;
		};

		if (localData.done > newData.done) {
			// const partServerExistedWords = syncList.map(i => i.text);
			localWordsToDelete = (await db.words.toCollection().toArray())
				.map(word => word.text);
			checkAllWords = true;
		}

		while (syncList.length) {
			let mustResync = false;
			for (const word of syncList) {
				this._isServerSync$.next((wordNumber - (wordCounter += 1)) * SYNC_PERCENTS / wordNumber);

				if (localData.done > newData.done) {
					let idx = localWordsToDelete.indexOf(word.text);
					if (idx > -1) {
						localWordsToDelete.splice(idx, 1);
					}
				}

				let lWord = await this.getLocalWordByText(word.text);

				if (lWord) {
					if (lWord.version === word.version && lWord.next_check_at === word.next_check_at) {
						continue;
					}

					mustResync = true;
					console.log('send to queue, updating', word.text);
					this.sentToQueue(async () => {
						const resWord = await this.requester.getUserWordById(userId, word.wordId, true);
						const localWord = await db.words.where('text').equals(word.text);
						// hack to correctly update version
						await localWord.modify({ version: -1});
						await localWord.modify({
							wordId: word.wordId,
							text: resWord.text,
							transcription: resWord.transcription,
							translate: resWord.translate,
							examples: resWord.examples,
							status: resWord.status,
							step: resWord.step,
							version: resWord.version,
							added_at: resWord.added_at,
							updated_at: resWord.updated_at,
							next_check_at: resWord.next_check_at,
						});
						console.log('received queue, updating', word.text);
					});
				} else {
					mustResync = true;
					console.log('send to queue, adding', word.text);
					this.sentToQueue(async () => {
						const resWord = await this.requester.getUserWordById(userId, word.wordId, true);
						await db.words.add({
							wordId: word.wordId,
							text: resWord.text,
							transcription: resWord.transcription,
							translate: resWord.translate,
							examples: resWord.examples,
							status: resWord.status,
							step: resWord.step,
							version: resWord.version,
							added_at: resWord.added_at,
							updated_at: resWord.updated_at,
							next_check_at: resWord.next_check_at,
						});
						console.log('received queue, adding', word.text);
					});
				}
			}

			if (!checkAllWords && mustResync) {
				let newLocalData = await waiter();

				if (newData.done === newLocalData.done && newData.new === newLocalData.new && newData.repeat === newLocalData.repeat) {
					this._isServerSync$.next(0);

					return newLocalData;
				}
			}

			syncList = await this.getSyncList(userId, page += 1, true);
		}

		if (checkAllWords) {
			await Promise.all(localWordsToDelete.map(word => {
				return this.delLocalWord(word);
			}));

			let newLocalData = await waiter();
			this._isServerSync$.next(0);

			return newLocalData;
		}

		this._isServerSync$.next(0);

		return newData;
	}

	sentToQueue (fn: Function) {
		this.queue.push(fn);

		this.runSync();
	}

	async runSync (resend = true) {
		if (this._isSyncingQueue$.getValue()) {
			return;
		}

		let retryArr = [];

		this._isSyncingQueue$.next(true);
		let fn = this.queue.shift();

		while (fn) {
			try {
				await fn();
			} catch (e) {
				console.log('=-=-=-error', e);
				retryArr.push(fn);
			}

			fn = this.queue.shift();
		}
		this._isSyncingQueue$.next(false);

		// todo df rework resync logic, potentially race condition
		if (resend && retryArr.length) {
			this.queue.concat(retryArr);
			await this.runSync(false);
		}
	}

	async safeRequest (reqName: string, ...dataSet: any) {
		await this.saveIntoStore(reqName, dataSet);

		if (this.isOnline()) {
			console.log('try resend not processed requests (1)');
			this.resendStoredRequests();
		}
	}

	saveIntoStore (reqName: string, data?: Array<any>) {
		return db.requests.add({
			name: reqName,
			data
		});
	}

	isOnline () {
		return this._isOnline$.getValue();
	}

	isOnSync () {
		return this._isServerSync$.getValue();
	}

	async storedRequestsLength () {
		return db.requests.toCollection().count();
	}

	async resendStoredRequests () {
		if (!this._canResendStoredRequests) {
			console.log(`==-request start: cannot ResendStoredRequests`);

			return false;
		}
		this._canResendStoredRequests = false;
		let request;

		let nr = await this.storedRequestsLength();
		while (nr) {
			try {
				request = await db.requests.toCollection().first();
				console.log(`==-request start: ${request.name}`);

				await this[request.name](...(request.data || []));
				await db.requests.where('id').equals(request.id).delete();
			} catch (err) {
				console.log(`=-=- error while request to server: ${request.name}`, JSON.stringify(request), JSON.stringify(err));
				if (err.status === 400) {
					await db.requests.where('id').equals(request.id).delete();
				} else {
					this._canResendStoredRequests = true;
					console.log(`==-request broken: ${request.name}`);

					throw err;
				}
			}
			console.log(`==-request completed: ${request.name}, rest: ${nr - 1}`);
			nr = await this.storedRequestsLength();
		}

		this._canResendStoredRequests = true;

		return true;
	}

	heartbeat () {
		if (this._heartbeatStarted) {
			return this._isOnline$;
		}

		this._heartbeatStarted = true;
		this._heartbeat();

		return this._isOnline$;
	}

	serverSync () {
		return this._isServerSync$;
	}

	async _heartbeat () {
		console.log('try heartbeat ' + new Date().toISOString());
		try {
			await this.requester.getHeartbeat(true, 10000);
			console.log('heartbeat OK');
			this._isOnline$.next(true);
		} catch (e) {
			console.log('=-=-heartbeat ERROR');
			this._isOnline$.next(false);
		}

		if (this._heartbeatStarted) {
			window.setTimeout(() => {
				this._heartbeat();
			}, 60_000);
		}
	}

	async getSyncList (userId, page, ignoreSw) {
		const res = await this.requester.getSyncList(userId, page, ignoreSw);

		return res.map(w => this.convertUserWord(w));
	}

	convertUserWord (word) {
		return {
			wordId: +word.id,
			text: word.text,
			transcription: word.transcription ? word.transcription.split('|')[0] : null,
			translate: word.translate,
			examples: word.examples,
			status: +word.status,
			step: +word.step,
			added_at: word.added_at ? DateTime.fromSQL(word.added_at, { zone: 'UTC'}).toMillis() : null,
			updated_at: word.updated_at ? DateTime.fromSQL(word.updated_at, { zone: 'UTC'}).toMillis() : null,
			next_check_at: word.next_check_at ? DateTime.fromSQL(word.next_check_at, { zone: 'UTC'}).toMillis() : null,
			version: +word.version,
		};
	}

	convertCommonWord (word) {
		return {
			wordId: +word.id,
			text: word.text,
			transcription: word.transcription.split('|')[0],
			translate: (word.translate || [{}]).reduce(
				(actualtranslate, translate) => actualtranslate.s > translate.s ? actualtranslate : translate, { }
			)?.['v'],
			examples: word.examples,
			status: +word.status,
			step: +word.step,
			added_at: word.added_at ? DateTime.fromSQL(word.added_at, { zone: 'UTC'}).toMillis() : null,
			updated_at: word.updated_at ? DateTime.fromSQL(word.updated_at, { zone: 'UTC'}).toMillis() : null,
			next_check_at: word.next_check_at ? DateTime.fromSQL(word.next_check_at, { zone: 'UTC'}).toMillis() : null,
			version: +word.version,
		};
	}

	async logout () {
		// todo df delete, only for testing
		// await Promise.all([{"name":"saveWord","data":[{"text":"coat","transcription":"kout","translate":"покриття","examples":[],"status":1,"tx":[]}],"id":170},{"name":"resetWord","data":[{"id":52,"text":"guitar","version":1}],"id":171}]
		// 	.map(req => {
		// 		return db.requests.add(req);
		// 	}));

		await db.options.clear();
		const requests = await db.requests.toArray();

		if (requests.length > 0) {
			const reqStr = JSON.stringify(requests);
			const userId = await this.getUserId();
			await db.options.add({ name: `userReq-${userId}`, data: reqStr});
			await db.requests.clear();
		}

		await this.deleteUser();

		// clean local user data
		await Promise.all([
			this.storage.del('studySet'),
			this.storage.del('currentWord'),
			db.words.clear()
		]);
	}

	async restoreSavedRequests () {
		const userId = await this.getUserId();
		const option = db.options.where('name').equals(`userReq-${userId}`);
		const reqStr = await option.first();

		if (reqStr) {
			const requests = JSON.parse(reqStr.data);

			for (const req of requests) {
				await db.requests.add(req);
			}

			await option.delete();
		}
	}

	async isFirstSync () {
		try {
			await db.options.add({
				name: 'firstSync',
				data: (new Date()).toISOString()
			});

			return true;
		} catch (e) {
			if (e.name === 'ConstraintError') {
				return false;
			}
			throw e;
		}
	}
}
