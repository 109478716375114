import packageInfo from './../../.version.json';

export const environment = {
	production: true,
	originHostName: 'english-dev.znonasharu.org.ua',
	host:     window.location.origin,
	apiUrl:   `${window.location.origin}/api`,
	frontUrl: window.location.origin,
	auth: {
		google: '468362020732-3tuduco6ka40vs4hioiubn0n2ats76g3.apps.googleusercontent.com',
		facebook: '2179608665613879'
	},
	messageHosts: [
		'' || `${window.location.origin}`,
	],
	startURL: '/dash',
	version: packageInfo.version,
};
