<mat-card *ngIf="word" class="float-card main-card">
	<mat-card-header>
		<mat-card-subtitle class="text-vertical-center" [matTooltip]="iconTitle">
			<mat-icon>{{icon}}</mat-icon>
			<span *ngIf="word.status === 2" style="margin-left: 5px;">{{word.next_check_at}}</span>
		</mat-card-subtitle>
	</mat-card-header>
	<mat-card-header class="title-card">

		<mat-card-title class="xlarge-text">{{word.text}}</mat-card-title>
		<mat-card-subtitle>/{{word.transcription}}/</mat-card-subtitle>

		<mat-card-title class="large-text">
			<button (click)="saySmt(word.text)" mat-fab color="primary" aria-label="Read the word">
				<mat-icon>volume_down</mat-icon>
			</button>
		</mat-card-title>
		<mat-card-title>{{word.translate}}</mat-card-title>
	</mat-card-header>
	<mat-card-content>
		<mat-divider></mat-divider>
		<div>
			<p *ngFor="let example of word.examples">
				➦ {{example.en}}<br>
				➥ {{example.org}}
			</p>
		</div>
		<mat-divider></mat-divider>
	</mat-card-content>
	<mat-card-actions class="center-block">
		<button (click)="delWord()" color="warn" mat-flat-button>видалити</button>
		<button (click)="resetWord()" color="accent" mat-flat-button>вчити з початку</button>
	</mat-card-actions>
	<mat-card-footer>
		<!--		<mat-progress-bar mode="indeterminate"></mat-progress-bar>-->
	</mat-card-footer>
</mat-card>
