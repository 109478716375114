import { Injectable } from '@angular/core';
// import {
// 	SocialUser,
// } from '@abacritt/angularx-social-login';
import * as crypto from 'crypto-js';

import { Subject } from 'rxjs';
import { UserModelService } from '../models/user-model.service';
import { RequesterService } from './requester.service';
import { StorageService } from './storage.service';

@Injectable()
export class AuthService {

	subscription;
	// socialUser: SocialUser;
	socialUser;
	loggedIn;
	subLogin = new Subject();

	constructor (
		private requester: RequesterService,
		private user: UserModelService,
		private storage: StorageService,
	) {
		// todo обдумать як юзать права (буде кидать по всіх сторінках якщо )
		// this.subscription = this.authService.authState.subscribe((data) => {
		// 	this.socialUser = data;
		// 	this.loggedIn = (data != null);
		//
		// 	console.log('=-=-=-=--=authServiceDiscribe', data);
		//
		// 	// if (this.loggedIn) {
		// 	//
		// 	// 	let sendData = {
		// 	// 		nam: data.name,
		// 	// 		email: data.email,
		// 	// 		photoUr: data.photoUrl,
		// 	// 		token: data.authToken,
		// 	// 		provider: data.provider,
		// 	// 	};
		// 	//
		// 	// 	return this.requester.loginWithSocial(sendData)
		// 	// 	.then((data) => {
		// 	// 		return this.authorization(data);
		// 	// 	});
		// 	// }
		// }, (err) => {
		// 	console.log('=-=-=-=--=authServiceDiscribe err', err);
		// });
	}

	preAuth (data) {
		return this.requester.preAuth(data)
		.then(res => {

			// console.log('preAuth', res);
		})
		.catch(e => {
			console.log('ERROR ', e);
		});
	}

	async login ({ email, password, extra }) {
		try {
			const res: { hash: string } = await this.requester.preAuth({ email });

			if (!res.hash) {
				throw new Error('ERROR_LOGIN');
			}
			let hash = crypto.SHA512(password).toString();
			let localHash = crypto.MD5(String(Date.now())).toString();

			let allHash = crypto.HmacSHA512(hash, res.hash + localHash)
			.toString();

			await this.requester.login({
				hash: res.hash,
				localHash,
				allHash,
				extra,
			}, {
				withCredentials: true
			});
		} catch (e) {
			console.log('ERROR ', e);
		}
	}

	async authorizationSync (data: { accessToken: string, refreshToken: string }) {

		const payloadBS = atob(data.accessToken.split('.')[1]);
		const payload = JSON.parse(payloadBS);

		await Promise.all([
			this.storage.set('token', data.accessToken),
			this.storage.set('refreshToken', data.refreshToken),
			this.storage.set('tokenExp', payload.exp * 1000),
		]);

		return this.user.getUser();
	}

	async logout () {

		await this.user.deleteUser();

		// clean local user data
		await this.user.logout();

		// todo вилогінюваця из социалки
		// this.authService.signOut();

		try {
			await this.requester.logout();
		} catch (e) {
			// cannot logout from server and delete auth cookies
			// todo to think what to do
		}
	}

	registration (data) {
		return this.requester.registration(data);
	}

	saveToken (data) {
		return this.authorizationSync(data);
	}

	resetPass (email) {

		return this.requester.resetPass(email);
	}

	newPass (data) {

		return this.requester.newPass(data);
	}

	userLogin () {
		return this.subLogin;
	}

	// {
	// 	"id": "11370352",
	// 	"name": "Juriy ",
	// 	"email": "juriy",
	// 	"photoUrl": "https://lh6./photo.jpg",
	// 	"firstName": "Juriy",
	// 	"lastName": "Ivanov",
	// 	"authToken": "ya29.Glv2BrFBg9cJAJMCA0ULVGmL9fem38D9p1",
	// 	"idToken": "eyJhbGciOiJSUzI1NiIsImtpZCI6IjI2dCtw",
	// 	"provider": "GOOGLE"
	// }

// 	authToken: "EAAeZBV7BDZAjcBAIVRXJ5ss23IDehsFjSV9CkZBnYODWa9LubodhwK2QZCgZCDK6eEHYkRkpsiZBP3y24LCPZA3r2A19T0ZAK4KfyMQBZCVaXvGE1ATzoilBzX01eTsZCEqfVZB2rVC8SbJu4OikWIZBf061eZBJXj63GGMw9SBwlWWTn61ll03R2GS9vqUzN99kQ0HJjZAvPUHs2UEQZDZD"
// ​
// 	email: "juriy_prof@ukr.net"
// ​
// 	facebook: Object { name: "Yuriy  Ivanov", email: "juriy_prof@ukr.net", first_name: "Yuriy", … }
// ​
// firstName: "Yuriy"
// ​
// id: "1066484326877724"
// ​
// lastName: "Ivanov"
// ​
// name: "Yuriy  Ivanov"
// ​
// photoUrl: "https://graph.facebook.com/1066484326877724/picture?type=normal"
// ​
// provider: "FACEBOOK"
	loginWithSocial (provider: string) {

		if (!provider) {
			throw new Error('Wrong provider');
		}

		// return this.authService.signIn(provider)
		// .then((data) => {
		//
		// 	console.log('=-=-- datalog', data);
		//
		// 	let sendData = {
		// 		name: data.name,
		// 		email: data.email,
		// 		photoUr: data.photoUrl,
		// 		token: data.authToken,
		// 		provider: data.provider,
		// 	};
		//
		// 	return this.requester.loginWithSocial(sendData)
		// 	.then((data) => {
		// 		return this.authorization(data);
		// 	});
		// });
	}
}
