<mat-card class="float-card text-center">
	<mat-card-content *ngIf="!showFinish; else finishView">
		<form [formGroup]="form" (ngSubmit)="onSubmit()">
			<div>
				<mat-form-field class="first-row">
					<input matInput formControlName="email" name="email"
						   type="email" placeholder="E-mail" maxlength="100">
				</mat-form-field>
				<p class="title" *ngIf="form.get('email').invalid && form.get('email').touched">
					Не правильний формат E-mail
				</p></div>
			<div class="button-row">
				<button mat-raised-button [disabled]="form.invalid" type="submit" color="primary">
					Скинути пароль
				</button>
			</div>
		</form>
	</mat-card-content>
	<ng-template #finishView>
		<mat-card-content>
			<h2>Запит на відновлення паролю відправлено. Перевірьте свою поштову скриньку</h2>
		</mat-card-content>
	</ng-template>
	<mat-divider></mat-divider>
	<mat-card-actions class="center-block">
		<a mat-stroked-button color="primary" routerLink="/registration">
			Реєстрація
		</a>
		<a mat-stroked-button color="primary" routerLink="/login" color="primary">
			Вхід
		</a>
	</mat-card-actions>
</mat-card>
