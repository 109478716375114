<mat-card class="float-card text-center">
	<mat-card-content *ngIf="!showFinish; else finishView">
			<form [formGroup]="form" (ngSubmit)="onSubmit()">
				<div class="container">
					<mat-form-field class="first-row">
						<input matInput formControlName="pass" name="pass"
							   type="password" placeholder="Новий пароль" maxlength="100">
					</mat-form-field>
					<p class="title" *ngIf="form.get('pass').invalid && form.get('pass').touched">
						Не коректна довжина паролю
					</p>
				</div>
				<div class="button-row first-row">
					<button mat-raised-button [disabled]="form.invalid" type="submit" color="primary">
						Зберегти пароль
					</button>
				</div>
			</form>
	</mat-card-content>
	<ng-template #finishView>
			<mat-card-content>
				<h2>Пароль змінено.</h2>
				<h3>Через {{redirectTime}} секунд Ви будете переадресовані на <a routerLink="/login">сторінку логіна</a>
				</h3>
			</mat-card-content>
		</ng-template>
	<mat-divider></mat-divider>
	<mat-card-actions class="center-block">
		<a mat-stroked-button color="primary" routerLink="/registration">
			Реєстрація
		</a>
		<a mat-stroked-button color="primary" routerLink="/login">
			Вхід
		</a>
	</mat-card-actions>
</mat-card>
