<div *ngIf="words && words.length" [ngSwitch]="step" style="display: inherit">
	<mat-card *ngSwitchCase="0" class="float-card main-card">
		<mat-card-header class="title-card">
			<mat-card-title class="large-text clickable" (click)="saySmt(words[index].text)">{{words[index].text}}
				🔊
			</mat-card-title>
			<mat-card-subtitle>/{{words[index].transcription}}/</mat-card-subtitle>
			<mat-card-title>{{words[index].translate}}</mat-card-title>
		</mat-card-header>
		<mat-divider class="inner-divider"></mat-divider>
		<mat-card-content class="content">
			<p *ngFor="let example of words[index].examples">
				➦ {{example.en}}<br>
				➥ {{example.org}}
			</p>
		</mat-card-content>
		<mat-divider class="inner-divider"></mat-divider>
		<mat-card-actions class="center-block">
			<button (click)="markAsKnown(words[this.index])" mat-stroked-button>⬅️ Знаю</button>
			<button (click)="addToStudySet()" mat-stroked-button>Вчити ➡️</button>
		</mat-card-actions>
		<mat-card-footer>
			<!--		<mat-progress-bar mode="indeterminate"></mat-progress-bar>-->
		</mat-card-footer>
	</mat-card>

	<mat-card *ngSwitchCase="1" class="float-card main-card">
		<mat-card-header class="title-card">
			<mat-card-title class="large-text clickable"
							(click)="saySmt(currentSet[index].text)">{{currentSet[index].text}} 🔊
			</mat-card-title>
			<mat-card-subtitle>/{{currentSet[index].transcription}}/</mat-card-subtitle>
		</mat-card-header>
		<mat-divider class="inner-divider"></mat-divider>
		<mat-card-content>
			<mat-selection-list>
				<mat-list-item [disabled]="!!currentSet[index]?.check"
							   *ngFor="let word of answersSet; let i = index;" class="border"
							   [ngClass]="currentSet[index]?.check && currentSet[index]?.check.index === i ? currentSet[index]?.check.class : ''"
							   (click)="checkAnswer(i)">
					<mat-icon matListItemIcon
							  color="{{currentSet[index]?.check && currentSet[index]?.check.index === i ? currentSet[index]?.check?.class : ''}}">
						{{currentSet[index]?.check && currentSet[index]?.check.index === i ? currentSet[index]?.check?.icon : 'circle'}}
					</mat-icon>
					<h3 matListItemTitle style="font-weight: bolder">
						<span>{{word.translate}}</span>
					</h3>
				</mat-list-item>
			</mat-selection-list>
		</mat-card-content>
		<mat-divider class="inner-divider"></mat-divider>
		<mat-card-actions class="center-block">
			<!--		<button (click)="markAsKnown(words[0])" mat-stroked-button>Знаю</button>-->
			<button [disabled]="disabledDKButton" (click)="dontKnowWord()" mat-stroked-button>⬅️ Не памʼятаю</button>
		</mat-card-actions>
		<mat-card-footer>
		</mat-card-footer>
	</mat-card>

	<mat-card *ngSwitchCase="2" class="float-card main-card">
		<mat-card-header class="title-card">
			<mat-card-title class="large-text">{{currentSet[index].translate}}</mat-card-title>
			<mat-card-subtitle>&nbsp;{{currentSet[index]?.check ? '/' + currentSet[index].transcription + '/' : ''}}&nbsp;</mat-card-subtitle>
		</mat-card-header>
		<mat-divider class="inner-divider"></mat-divider>
		<mat-card-content>
			<mat-selection-list>
				<mat-list-item [disabled]="!!currentSet[index]?.check"
							   *ngFor="let word of answersSet; let i = index;" class="border"
							   [ngClass]="currentSet[index]?.check && currentSet[index]?.check.index === i ? currentSet[index]?.check.class : ''"
							   (click)="checkAnswer(i)">
					<mat-icon matListItemIcon
							  color="{{currentSet[index]?.check && currentSet[index]?.check.index === i ? currentSet[index]?.check?.class : ''}}">
						{{currentSet[index]?.check && currentSet[index]?.check.index === i ? currentSet[index]?.check?.icon : 'circle'}}
					</mat-icon>
					<h3 matListItemTitle style="font-weight: bolder">
						<span>{{word.text}}</span>
					</h3>
				</mat-list-item>
			</mat-selection-list>
		</mat-card-content>
		<mat-divider class="inner-divider"></mat-divider>
		<mat-card-actions class="center-block">
			<!--		<button (click)="markAsKnown(words[0])" mat-stroked-button>Знаю</button>-->
			<button (click)="dontKnowWord()" [disabled]="disabledDKButton" mat-stroked-button>⬅️ Не памʼятаю</button>
		</mat-card-actions>
		<mat-card-footer>
		</mat-card-footer>
	</mat-card>

	<mat-card *ngSwitchCase="3" class="float-card main-card">
		<mat-card-header class="title-card tall">
			<mat-card-title class="large-text">
				<button mat-fab color="primary" (click)="saySmt(currentSet[index].text)"
						aria-label="Say word">
					<mat-icon>volume_down</mat-icon>
				</button>
			</mat-card-title>
			<mat-card-title class="large-text">&nbsp;{{currentSet[this.index]?.check?.userText}}&nbsp;
			</mat-card-title>
			<mat-card-title>&nbsp;{{showWord && currentSet[index].translate}}&nbsp;</mat-card-title>
			<mat-card-subtitle>&nbsp;{{showWord && '/' + currentSet[index].transcription + '/'}}&nbsp;
			</mat-card-subtitle>
		</mat-card-header>
		<mat-divider class="inner-divider"></mat-divider>
		<mat-card-content class="short button-block">
			<button [color]="character.color ? character.color : ''"
					*ngFor="let character of randomLetters; let i = index;"
					(click)="addLetter(i)"
					[disabled]="character.dissabled"
					class="letter-btn text-uppercase {{character.dissabled ? 'hidden' : ''}}" mat-stroked-button
			>
				{{character.text}}
			</button>
		</mat-card-content>
		<mat-divider class="inner-divider"></mat-divider>
		<mat-action-list class="center-block">
			<button (click)="dontKnowWord()" [disabled]="disabledDKButton" mat-stroked-button>⬅️ Не памʼятаю</button>
		</mat-action-list>
		<mat-card-footer>
		</mat-card-footer>
	</mat-card>

	<mat-card *ngSwitchCase="4" class="float-card main-card">
		<mat-card-header class="title-card">
			<mat-card-title class="large-text">{{currentSet[index].translate}}</mat-card-title>
			<mat-card-title class="large-text">&nbsp;{{showWord && currentSet[index].text}}&nbsp;</mat-card-title>
			<mat-card-subtitle>&nbsp;{{showWord && '/' + currentSet[index].transcription + '/'}}&nbsp;
			</mat-card-subtitle>
		</mat-card-header>
		<mat-divider class="inner-divider"></mat-divider>
		<mat-card-content>
			<mat-form-field style="width: 100%" (keyup.enter)="checkAnswer4()">
				<input #engInput matInput [(ngModel)]="typedWord" placeholder="Напишіть слово англійською"
					   class="search-input" autofocus>
				<!--		<button mat-icon-button matSuffix [attr.aria-label]="'Hide password'" [attr.aria-pressed]="hide">-->
			</mat-form-field>
		</mat-card-content>
		<mat-divider class="inner-divider"></mat-divider>
		<mat-card-actions class="center-block">
			<button (click)="dontKnowWord()" [disabled]="disabledDKButton" mat-stroked-button>
				⬅️ Не памʼятаю
			</button>
			<button (click)="checkAnswer4()" [disabled]="!typedWord || disabledDKButton" mat-stroked-button>
				Перевірити ➡️
			</button>
		</mat-card-actions>
		<mat-card-footer>
		</mat-card-footer>
	</mat-card>

	<mat-card *ngSwitchCase="5" class="float-card main-card">
		<mat-card-header class="title-card">
			<mat-card-title class="large-text">Вивчення завершено</mat-card-title>
		</mat-card-header>
		<mat-divider class="inner-divider"></mat-divider>
		<mat-card-content>
			<mat-action-list>
				<mat-list-item *ngFor="let word of words; let i = index;" class="border"
							   routerLink="/dictionary/{{word.text}}">
					<mat-icon matListItemIcon
							  color="{{word.check?.status ? 'accent' : 'warn' }}">
						{{word.check?.status ? 'check_circle' : 'do_not_disturb_on' }}
					</mat-icon>
					<h3 matListItemTitle style="font-weight: bolder">
						<span>{{word.text}} - {{word.translate}}</span>
					</h3>
				</mat-list-item>
			</mat-action-list>
		</mat-card-content>
		<mat-divider class="inner-divider"></mat-divider>
		<mat-card-actions class="center-block">
			<button routerLink="/dash" mat-stroked-button>
				⬅️ На головну
			</button>
			<button (click)="reset()" mat-stroked-button>
				Вчити ще ➡️
			</button>
		</mat-card-actions>
		<mat-card-footer>
		</mat-card-footer>
	</mat-card>
</div>
<div *ngIf="words && !words?.length" style="display: inherit">
	<mat-card class="float-card main-card">
		<mat-card-header class="title-card">
			<mat-card-title class="large-text">Недостатньо слів для вивчення</mat-card-title>
			<!--				<mat-card-subtitle> </mat-card-subtitle>-->
		</mat-card-header>
		<mat-divider class="inner-divider"></mat-divider>
		<mat-card-content class="content">
				Мінімальна кількість cлів для вивчення 5,
				<br><strong>Ви маєте:    {{wordsOnStudy}}</strong>
				<br>додайте більше слів
		</mat-card-content>
		<mat-divider class="inner-divider"></mat-divider>
		<mat-card-actions class="center-block">
			<button routerLink="/dash" mat-stroked-button>На головну</button>
			<button routerLink="/add" mat-stroked-button>Додати слова</button>
		</mat-card-actions>
		<mat-card-footer>
			<!--		<mat-progress-bar mode="indeterminate"></mat-progress-bar>-->
		</mat-card-footer>
	</mat-card>
</div>
