import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, Subject } from 'rxjs';

@Injectable({
	providedIn: 'root'
})
export class MetaInfoService {

	private titleSubject: BehaviorSubject<string> = new BehaviorSubject('load...');
	title$: Observable<string> = this.titleSubject.asObservable();

	private rightMenuSubject: BehaviorSubject<Array<any> | Object> = new BehaviorSubject(null);
	rightMenu$: Observable<Array<any> | Object> = this.rightMenuSubject.asObservable();

	private leftMenuSubject: BehaviorSubject<Array<any> | Object> = new BehaviorSubject(null);
	leftMenu$: Observable<Array<any> | Object> = this.leftMenuSubject.asObservable();

	private errorSubject: BehaviorSubject<Object> = new BehaviorSubject(null);
	error$: Observable<Object> = this.errorSubject.asObservable();

	private showPreloaderSubject: BehaviorSubject<Object> = new BehaviorSubject(false);
	showPreloader$: Observable<Object> = this.showPreloaderSubject.asObservable();

	setData (data: { title?: string, leftMenu?: { }, rightMenu?: { } }) {
		setTimeout(() => {
			this.setTitle(data.title);
			this.rightMenuSubject.next(data.rightMenu);
			this.leftMenuSubject.next(data.leftMenu);
		}, 0);
	}

	setTitle (title) {
		setTimeout(() => {
			this.titleSubject.next(title);
		});
	}

	showPreloader (data) {
		setTimeout(() => {
			this.showPreloaderSubject.next(data);
		}, 0);
	}

	showError (error) {
		this.showPreloader(false);

		setTimeout(() => {
			this.errorSubject.next(error);
		});
	}
}
