<div class="right-height">
	<mat-drawer-container class="right-height">
		<mat-drawer mode="over" #sidenav>
			<div class="account-wrapper">
				<div class="account">
					<div class="avatar">
						<mat-icon (click)="avatarClick()">account_circle</mat-icon>
					</div>
					<div>{{ user.name ? user.name : 'X-user' }}</div>
					<div class="flex">
						<span class="subtitle fixed-line">
							{{ user.email ? user.email : '' }}
						</span>
						<mat-menu #userMenu="matMenu">
							<a mat-menu-item routerLink="/user-info" (click)="sidenav.close()">
								Нaлаштування
							</a>
							<button mat-menu-item (click)="goLogout(sidenav)">
								Вийти
							</button>
						</mat-menu>
						<button class="right" mat-icon-button [matMenuTriggerFor]="userMenu">
							<mat-icon>more_vert</mat-icon>
						</button>
					</div>
				</div>
			</div>
			<a mat-button class="side-menu"
			   (click)="sidenav.close()"
			   routerLink="/dash"
			   routerLinkActive="active"
			>
				<mat-icon>dashboard</mat-icon>
				<span>Дашборд</span>
			</a>
			<a mat-button class="side-menu"
			   (click)="sidenav.close()"
			   routerLink="/dictionary"
			>
				<mat-icon>local_library</mat-icon>
				<span>Словник</span>
			</a>
			<a mat-button class="side-menu"
			   (click)="sidenav.close()"
			   routerLink="{{'/repeat'}}">
				<mat-icon>arrow_circle_up</mat-icon>
				<span>Повторення</span>
			</a>

			<a mat-button class="side-menu"
			   (click)="sidenav.close()"
			   routerLink="{{'/study'}}">
				<mat-icon>whatshot</mat-icon>
				<span>Вчити нові слова</span>
			</a>

			<a mat-button class="side-menu"
			   (click)="sidenav.close()"
			   routerLink="/add">
				<mat-icon>add_circle</mat-icon>
				<span>Додати слово</span>
			</a>

			<a mat-button class="side-menu"
			   (click)="sidenav.close()"
			   routerLink="/sets">
				<mat-icon>dynamic_feed</mat-icon>
				<span>Набори слів</span>
			</a>

			<div class="bottom-content">
				<a mat-button disableRipple="true" class="side-menu side-menu-bottom"
				   (click)="sidenav.close()"
				   routerLink="/">
					<mat-icon>info</mat-icon>
					<span>Про застосунок</span>
				</a>
			</div>
		</mat-drawer>

		<mat-toolbar color="primary">
			<mat-toolbar-row>
				<button mat-icon-button *ngIf="!demoMode; else placeholder" (click)="leftMenuClick(sidenav)">
					<mat-icon>{{leftMenu ? leftMenu.icon : 'menu'}}</mat-icon>
				</button>

				<ng-template #placeholder>
					<div class="button-placeholder"></div>
				</ng-template>
				<div>{{metaInfo.title$ | async}}</div>
				<div [ngSwitch]="showType">
					<mat-icon class="fa-spin" *ngIf="serverSync$ | async" style="width: 48px;" aria-hidden="false" aria-label="Connection is absent">
						sync
					</mat-icon>
					<mat-icon style="width: 48px;" *ngIf="isNoConnect || !(heartbeat$ | async)" aria-hidden="false" aria-label="Connection is absent">
						wifi_off
					</mat-icon>
					<div *ngIf="!(serverSync$ | async) && (!isNoConnect && (heartbeat$ | async))" class="button-placeholder"></div>
					<div *ngSwitchCase="1">
						<mat-menu #menu="matMenu">
							<button *ngFor="let it of rightMenu" mat-menu-item
									routerLink="{{it.href}}"
							>{{it.title}}</button>
						</mat-menu>
						<button mat-icon-button [matMenuTriggerFor]="menu">
							<mat-icon>more_vert</mat-icon>
						</button>
					</div>
					<a *ngSwitchCase="2" routerLink="{{rightMenu.href}}" [queryParams]="rightMenu.queryParams" mat-icon-button>
						<mat-icon>{{rightMenu.icon}}</mat-icon>
					</a>
				</div>
				<ng-template #tmp>
					<div></div>
				</ng-template>
			</mat-toolbar-row>
		</mat-toolbar>
		<mat-progress-bar *ngIf="serverSync$ | async" mode="determinate" [value]="100 - (serverSync$ | async)"></mat-progress-bar>
<!--		<div style="height: calc(100% - 56px - 15px - 115px - 400px)">-->
<!--			todo df delete-->
		<div style="height: calc(100% - 56px - 15px - 100px)">
			<router-outlet></router-outlet>
		</div>

		<app-preloader [diameter]="100" [show]="showPreloader"></app-preloader>

		<app-error-shower [error]="netError" [showError]="showError"></app-error-shower>

	</mat-drawer-container>
</div>
