<mat-card *ngIf="form" class="float-card">
	<mat-card-content>
		<div class="text-left">
<!--			<mat-divider></mat-divider>-->
			авторизуйтесь
		</div>
		<form style="text-align: center" [formGroup]="form" (ngSubmit)="onSubmit()">
			<div>
				<mat-form-field class="first-row">
					<input matInput formControlName="email" name="email"
						   type="email" placeholder="E-mail" maxlength="100">
				</mat-form-field>
				<p class="title" *ngIf="form.get('email').invalid && form.get('email').touched">
				Не вірний формат E-mail
				</p>
			</div>
			<div>
				<mat-form-field class="example-full-width">
					<input matInput formControlName="password" type="password"
						   name="password" placeholder="Password" maxlength="100">
				</mat-form-field>
				<p class="title" *ngIf="form.get('password').invalid && form.get('password').touched">
				Поле не має бути менше 6-ти символів
				</p>
			</div>
			<section class="button-row first-row">
				<mat-checkbox formControlName="extra">Запамʼятати мене</mat-checkbox>
			</section>
			<div class="button-row first-row">
				<button mat-raised-button [disabled]="form.invalid || disableButton"
						type="submit" color="primary">Вхід
				</button>
			</div>
		</form>
		<div *ngIf="showSocialLogin" class="first-row">
			<div class="text-left" style="margin-bottom: 16px;">
				<mat-divider></mat-divider>
				чи увійдіть за допомогою соц. мереж
			</div>
			<app-preloader [global]="false" [diameter]="50" [show]="showSpinner"></app-preloader>
			<iframe #iframe id="socialLogin" name="socialLogin" [src]="frameSRC"></iframe>
		</div>
	</mat-card-content>
	<mat-divider></mat-divider>
	<mat-card-actions class="center-block">
		<a mat-stroked-button color="primary" routerLink="/reset-pass">
			Скидання пароля
		</a>
		<a mat-stroked-button color="primary" routerLink="/registration">
			Реєстрація
		</a>
	</mat-card-actions>
</mat-card>
