import { Component, OnInit, QueryList, ViewChild, ViewChildren } from '@angular/core';

import { ActivatedRoute } from '@angular/router';
import { OffsetTopDirective } from '../../common/directives/offset-top.directive';
import { ScrollableDirective } from '../../common/directives/scrollable.directive';
import { UserModelService } from '../../common/models/user-model.service';
import { MetaInfoService } from '../../common/services/meta-info.service';

@Component({
	selector: 'app-set-list',
	templateUrl: './set-list.component.html',
	styleUrl: './set-list.component.scss'
})
export class SetListComponent implements OnInit {
	constructor (
		public metaInfo: MetaInfoService,
		private userModel: UserModelService,
		private currentRout: ActivatedRoute,
	) {
	}

	@ViewChildren(OffsetTopDirective) listItems: QueryList<OffsetTopDirective>;
	@ViewChild(ScrollableDirective) list: ScrollableDirective;

	offline = false;
	listName = this.currentRout.snapshot.params.listId;
	title = this.listName;
	words = [];
	page = 0;
	currentPage = 0;
	parentPage = 'sets';
	currentWord = null;

	async ngOnInit (): Promise<void> {
		this.metaInfo.setData({
			leftMenu: {
				icon: 'arrow_back',
				href: this.parentPage,
				// queryParams: this.backQueryParams
			},
			rightMenu: null,
			title: this.title,
		});

		this.metaInfo.showPreloader(true);

		this.currentPage = parseInt(this.currentRout.snapshot.queryParamMap.get('p'), 10) || 0;
		this.currentWord = this.currentRout.snapshot.queryParamMap.get('w') || null;

		if (this.userModel.isOnline()) {
			try {
				for (let i = 0; i <= this.currentPage; i++) {
					let newWords = await this.userModel.getUserWordListByName(this.listName, i);
					newWords     = await this.matchWithLocalWords(newWords);

					this.words = this.words.concat(newWords);
				}

				this.page = this.currentPage;

				if (this.currentWord) {
					setTimeout(() => {
						let q = this.listItems.find((item, i) => {
							return this.currentWord === item.text;
						})?.offsetTop || 0;
						if (q) {
							this.list.scrollTop = q;
						}
					}, 0);

					this.page = this.currentPage;
				}

			} catch (e) {
				this.offline = true;
				this.metaInfo.showError(e);
			}

			this.metaInfo.showPreloader(false);
		} else {
			this.offline = true;
		}
	}

	matchWithLocalWords (words) {
		return Promise.all(words.map(async w => {
			const localWord = await this.userModel.getLocalWordByText(w.text);
			if (localWord) {
				w.translate = localWord.translate;
				w.status = localWord.status;
			}

			return w;
		}));
	}

	getIcon (i) {
		switch (this.words[i].status * 1) {
			case 3:
				return 'task_alt';
			case 2:
				return 'arrow_circle_up';
			case 1:
				return 'radio_button_unchecked';
			default:
				return 'add';
		}
	}

	async onScrollDown () {
		if (this.page >= this.currentPage) {
			const newWords = await this.userModel.getUserWordListByName(this.listName, this.page + 1);

			if (newWords.length) {
				this.page = this.page + 1;
			}

			this.words = this.words.concat(newWords);
		}
	}

	onScrollUp () {
		// console.log('=-=-=-up; page');
	}
}
