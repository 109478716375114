import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { UserModelService } from '../../common/models/user-model.service';
import { MetaInfoService } from '../../common/services/meta-info.service';
import { RequesterService } from '../../common/services/requester.service';

@Component({
	selector: 'app-sets',
	templateUrl: './sets.component.html',
	styleUrls: ['./sets.component.scss']
})
export class SetsComponent implements OnInit {
	constructor (
		public metaInfo: MetaInfoService,
		private requester: RequesterService,
		private userModel: UserModelService,
		private router: Router,
		private route: ActivatedRoute,
	) {
	}

	title = 'Набори слів';
	wordsLists;
	offline = false;

	async ngOnInit (): Promise<void> {

		this.metaInfo.setData({
			leftMenu: null,
			rightMenu: null,
			title: this.title,
		});

		if (this.userModel.isOnline()) {
			try {
				this.metaInfo.showPreloader(true);
				this.wordsLists = await this.userModel.getUserWordsLists();
			} catch (e) {
				this.offline = true;
				this.metaInfo.showError(e);
			}
			this.metaInfo.showPreloader(false);
		} else {
			this.offline = true;
		}
	}
}
