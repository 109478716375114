import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
	selector: 'app-dialog',
	templateUrl: './dialog.component.html',
	styleUrls: ['./dialog.component.css']
})
export class DialogComponent {

	// using of html
	// html: this.sanitized.bypassSecurityTrustHtml(`
	// 	<textarea rows="3" style="width: 400px;">${this.foundWord.examples[index].en}</textarea>
	// `),

	constructor (
		public dialogRef: MatDialogRef<DialogComponent>,
		@Inject(MAT_DIALOG_DATA) public data: any
	) {
	}

	onLeftClick () {
		this.dialogRef.close({ type: 'leftClick', data: this.data});
	}

	onRightClick () {
		this.dialogRef.close({ type: 'rightClick', data: this.data});
	}

	clean (fieldName) {
		this.data.example[fieldName] = '';
	}
}
