import { CommonModule } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';

import {
	FacebookLoginProvider,
	GoogleLoginProvider,
	SocialAuthServiceConfig,
	SocialLoginModule as ExternalLogin,
} from '@abacritt/angularx-social-login';

import { environment } from '../../environments/environment';
import { UserModelService } from '../common/models/user-model.service';
import { AuthService } from '../common/services/auth.service';
import { RequesterService } from '../common/services/requester.service';
import { HighlightDirective } from './highlight.directive';
import { SocialLoginRoutingModule } from './social-login-routing.module';
import { SocialLoginComponent } from './social-login.component';

const fbLoginOptions = {
	scope: 'email',
	return_scopes: true,
	enable_profile_selector: true
};

const googleLoginOptions = {
	scopes: 'profile email',
	oneTapEnabled: false
};

// @ts-ignore
let configProviders = [
	{
		id: GoogleLoginProvider.PROVIDER_ID,
		provider: new GoogleLoginProvider(environment.auth.google, googleLoginOptions)
	},
	// {
	// 	id: FacebookLoginProvider.PROVIDER_ID,
	// 	provider: new FacebookLoginProvider(environment.auth.facebook, fbLoginOptions)
	// }
];

export function provideConfig () {
	return configProviders;
}

@NgModule({
	declarations: [
		SocialLoginComponent,
		HighlightDirective,
	],
	imports: [
		CommonModule,
		MatButtonModule,
		HttpClientModule,
		ExternalLogin,
		SocialLoginRoutingModule,
	],
	providers: [
		{
			provide: AuthService,
			useFactory: provideConfig
		},
		{
			provide: 'SocialAuthServiceConfig',
			useValue: {
				autoLogin: false,
				providers: configProviders,
				onError: err => {
					console.error(err);
				}
			} as SocialAuthServiceConfig,
		},
		RequesterService,
		UserModelService,
		AuthService,
	]
})
export class SocialLoginModule {
}
