<mat-card class="float-card text-center">
	<mat-form-field class="wild-input">
		<mat-label>Введіть транскрипцію</mat-label>
		<input matInput type="text" [(ngModel)]="time">
	</mat-form-field>

	<button  (click)="searchButton()">
		<mat-icon matTooltip="Тільки англійські літери!!!">search</mat-icon>
	</button>
</mat-card>
