import { NgModule } from '@angular/core';
import { BrowserModule, HAMMER_GESTURE_CONFIG } from '@angular/platform-browser';
import { ServiceWorkerModule } from '@angular/service-worker';
import { HammerConfig } from './hammer-config';

import { environment } from '../environments/environment';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { PageHolderModule } from './page-holder/page-holder.module';
import { SocialLoginModule } from './social-login/social-login.module';
import { SystemModule } from './system/system.module';

@NgModule({
	declarations: [
		AppComponent,
	],
	imports: [
		BrowserModule,
		ServiceWorkerModule.register('ngsw-worker.js', {enabled: environment.production}),
		PageHolderModule,
		SocialLoginModule,
		SystemModule,
		AppRoutingModule,
	],
	providers: [
		{
			provide: HAMMER_GESTURE_CONFIG,
			useClass: HammerConfig,
		},
	],
	bootstrap: [AppComponent]
})
export class AppModule {
}
