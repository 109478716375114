import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { SwPush } from '@angular/service-worker';

import { DialogComponent } from '../../common/components/dialog/dialog.component';
import { UserModelService } from '../../common/models/user-model.service';
import { AuthService } from '../../common/services/auth.service';
import { MetaInfoService } from '../../common/services/meta-info.service';
import { RequesterService } from '../../common/services/requester.service';
import { SpeakerService } from '../../common/services/speaker.service';
import { StorageService } from '../../common/services/storage.service';

@Component({
	selector: 'app-user-info',
	templateUrl: './user-info.component.html',
	styleUrls: ['./user-info.component.css'],
})
export class UserInfoComponent implements OnInit  {

	user: { name: string, email: string };
	color = 'primary';
	loaderShow = true;

	not_disabled = false;

	notification = false;
	subscription;

	location = false;
	loc_disabled = true;

	listVoices = null;
	selectedVoice = null;

	constructor (
		private router: Router,
		private requester: RequesterService,
		private swPush: SwPush,
		private auth: AuthService,
		public  dialog: MatDialog,
		public  userModel: UserModelService,
		public  metaInfo: MetaInfoService,
		public  speaker: SpeakerService,
		public  storage: StorageService,
	) {
	}

	async ngOnInit () {

		await this.metaInfo.setData({
			rightMenu: {
				icon: 'cancel',
				href: '/dash',
			},
			title: 'Налаштування'
		});

		this.metaInfo.showPreloader(true);

		// todo df open when work with push
		// this.not_disabled = !this.swPush.isEnabled;
		//
		// this.swPush.subscription.subscribe(sub => {
		// 	if (sub) {
		// 		this.subscription = sub;
		// 		this.notification = true;
		// 	}
		// });
		//
		// this.swPush.messages.subscribe(messages => {
		// 	// console.log('=-=--messages', messages);
		// });
		//
		// this.swPush.notificationClicks.subscribe(messages => {
		// 	// console.log('=-=--notificationClicks', messages);
		// });

		this.requester.getUser()
		.then(userData => {
			this.userModel.createUser(userData)
			.then(user => {
				this.metaInfo.showPreloader(false);

				this.user = user;
			});

			this.loaderShow = false;
		})
		.catch(err => {
			this.metaInfo.showError(err);
		});

		let voice = await this.speaker.getVoice();
		this.selectedVoice = voice.name;
		this.listVoices = await this.speaker.getVoiceList();

		console.log('=-=', voice.name);
	}

	toggleNotification () {
		return;

		if (this.not_disabled) {
			return;
		}

		if (!this.notification) {

			this.swPush.requestSubscription({
				serverPublicKey: 'BCbA/Kpn6zYHuzOwgBKk2Jiv4PJ2RWieNNSBZNpEqc8wx7a21KJmSwqTw8JhDqbUXzFab8hXOEWmied3Tr/INEQ=',
			})
			.then(sub => {

				this.requester.postUserSub(sub)
				.catch(e => {
					this.notification = false;

					return this.swPush.unsubscribe();
				});
			});
		} else {
			this.notification = false;

			return this.unSub();
		}
	}

	locationTogle () {

	}

	unSub () {

		if (!this.subscription) {
			return;
		}

		return this.swPush.unsubscribe()
		.then(() => {
			this.requester.delUserSub(this.subscription)
			.then(() => {
				this.subscription = null;
			});
		});
	}

	async logout () {
		await this.auth.logout();
		await this.router.navigate(['login']);
	}

	editUser () {

		const dialogRef = this.dialog.open(DialogComponent, {
			width: '450px',
			data: {
				showInput: true,
				inputValue: this.user.name,
				title: 'Редагування',
				rightBtnTitle: 'Зберегти',
				leftBtnTitle: 'Відміна',
				leftBtnIcon: 'cancel',
			},
		});

		dialogRef.afterClosed().subscribe(async result => {

			if (result && result.type === 'rightClick') {
				await this.requester.updateUser({ name: result.data.inputValue });
				const response = await this.userModel.updateUser({ name: result.data.inputValue });

				this.user.name = response.name;
			}
		});
	}

	async changeVoice () {
		await this.speaker.changeVoice(this.selectedVoice);
		this.speaker.saySmt('test new voice');
	}
}
