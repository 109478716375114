import { Directive, ElementRef, Input, OnInit } from '@angular/core';

import { SocialAuthService } from '@abacritt/angularx-social-login';
import { take } from 'rxjs/operators';

@Directive({
	// eslint-disable-next-line @angular-eslint/directive-selector
	selector: 'google-signin-butt'
})
export class HighlightDirective implements OnInit {

	@Input('selectable') option: boolean;

	constructor (private el: ElementRef, private socialAuthService: SocialAuthService) {
	}

	ngOnInit () {
		// if (!this.option) { return; }
		this.socialAuthService.initState.pipe(take(1)).subscribe(() => {

			// @ts-ignore
			google.accounts.id.renderButton(this.el.nativeElement, {
				type: 'standard',
				size: 'medium',
				text: 'signin_with',
				theme: 'filled_blue'
			});
		});
	}
}
