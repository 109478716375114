<mat-card class="float-card main-card">
	<mat-card-content *ngIf="wordsLists">
		<div>
			<mat-selection-list>
				<div class="both-button-row">
					<mat-list-item *ngFor="let list of wordsLists; let i = index;"
								   class="border" [ngClass]="list ? '' : 'app-warning'"
								   routerLink="/sets/{{list.id}}">
						<mat-icon matListItemIcon>{{ 'dynamic_feed' }}</mat-icon>
						<div matListItemTitle style="font-weight: bolder; display: flex; justify-content: space-between;">
							<span>{{list.name}}:</span> <span>{{list.new}}/{{list.all}}</span>
						</div>
					</mat-list-item>
				</div>
			</mat-selection-list>
		</div>
	</mat-card-content>

	<mat-card-content *ngIf="!wordsLists && !offline">
		<mat-card-header class="title-card">
			<mat-card-title class="large-text"></mat-card-title>
		</mat-card-header>
		<mat-divider></mat-divider>
		<div class="text-center" style="margin: 16px;">
		</div>
		<mat-divider></mat-divider>
		<mat-card-actions class="center-block">

		</mat-card-actions>
	</mat-card-content>

	<mat-card-content *ngIf="offline">
		<mat-card-header class="title-card">
			<mat-card-title class="large-text">Даний функціонал працює тільки в режімі онлайн</mat-card-title>
		</mat-card-header>
		<mat-divider></mat-divider>
		<div class="text-center" style="margin: 16px;">
			<mat-icon class="big-icon">wifi_off</mat-icon>
		</div>
	</mat-card-content>

	<mat-divider></mat-divider>
	<mat-card-actions class="center-block">
		<button mat-stroked-button routerLink="/dash">Головна</button>
	</mat-card-actions>
	<mat-card-footer>
		<!--		<mat-progress-bar mode="indeterminate"></mat-progress-bar>-->
	</mat-card-footer>
</mat-card>
