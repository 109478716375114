import { Component, HostListener, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { UserModelService } from '../../common/models/user-model.service';
import { MetaInfoService } from '../../common/services/meta-info.service';
import { SpeakerService } from '../../common/services/speaker.service';

@Component({
	selector: 'app-lists',
	templateUrl: './lists-add.component.html',
	styleUrls: ['./lists-add.component.scss']
})
export class ListsAddComponent implements OnInit {
	constructor (
		public metaInfo: MetaInfoService,
		private userModel: UserModelService,
		private currentRout: ActivatedRoute,
		private speaker: SpeakerService,
		private router: Router,
	) {
	}

	word = this.currentRout.snapshot.params.word;
	listName = this.currentRout.snapshot.params.listName;

	title = `${this.word}`;

	backTo = { backto: this.router.url };

	_finish = false;
	flip = false;
	index = 0;
	doneArr = [];
	resetArr = [];
	lockNext = false;
	lockReset = false;
	words;
	offline = false;
	currentPage = 0;

	@HostListener('document:keydown', ['$event'])
	handleDeleteKeyboardEvent (event: KeyboardEvent) {
		switch (event.key) {
			case 'ArrowUp':
			case 'ArrowDown':
				this.flipCard();
				break;
			case 'ArrowLeft':
				this.reset();
				break;
			case 'ArrowRight':
				this.saveToLearn();
				break;
		}
	}

	async ngOnInit (): Promise<void> {
		this.metaInfo.showPreloader(true);

		this.currentPage = parseInt(this.currentRout.snapshot.queryParamMap.get('p'), 10) || 0;

		this.metaInfo.setData({
			leftMenu: {
				icon: 'arrow_back',
				href: `sets/${this.listName}`,
				queryParams: { p: this.currentPage, w: this.word }
			},
			rightMenu: null,
			title: this.title,
		});

		let localWord;

		try {
			localWord = await this.userModel.getLocalWordByText(this.word);
			if (!localWord) {
				localWord = await this.userModel.getWordByText(this.word);
			}

			this.words = [localWord];
			this.saySmt(this.words[this.index].text);
		} catch (e) {
			this.metaInfo.showError(e);
		}

		this.metaInfo.showPreloader(false);
	}

	flipCard () {
		this.flip = !this.flip;
	}

	async nextStep () {
		if (this.lockNext || !!this.words[this.index].status) {
			return;
		}
		this.lockNext = true;
		this.flip = false;
		this.doneArr.push(this.words[this.index].id);
		await new Promise(r => setTimeout(r, 400));
		if (this.index + 1 < this.words.length) {
			this.index++;
			this.saySmt(this.words[this.index].text);
		} else {
			this.finish();
		}
		this.lockNext = false;
	}

	async reset () {
		if (this.lockReset) {
			return;
		}
		this.lockReset = true;
		this.flip = false;
		await new Promise(r => setTimeout(r, 400));
		this.resetArr.push(this.words[this.index].id);
		if (this.index + 1 < this.words.length) {
			this.index++;
			this.saySmt(this.words[this.index].text);
		} else {
			this.finish();
		}
		this.lockReset = false;
	}

	async finish () {
		this._finish = true;
		try {
			this.metaInfo.showPreloader(true);
			let data = await this.userModel.saveLocalRepeatWords({ done: this.doneArr, reset: this.resetArr });
		} catch (e) {
			this.metaInfo.showError(e);
		}
		this.metaInfo.showPreloader(false);
	}

	async getWords () {
		try {
			this._finish = false;
			this.words = null;
			this.flip = false;
			this.index = 0;
			this.doneArr = [];
			this.resetArr = [];

			this.metaInfo.showPreloader(true);
			this.words = (await this.userModel.getUserWordListsById(this.listName, true)).map(w => {
				w.transcription = w.transcription ? w.transcription.split('|')[0] : w.transcription;

				if (w.translate) {
					w.translate = w.translate.sort((a, b) => {
						return a.s > b.s ? -1 : 1;
					})[0].v;
				}

				return w;
			});
			this.metaInfo.showPreloader(false);
			if (this.words && this.words.length > 0) {
				this.saySmt(this.words[this.index].text);
			}
		} catch (err) {
			this.metaInfo.showError(err);
		}
	}

	saySmt (text) {
		this.speaker.saySmt(text);
	}

	async saveAsKnown () {
		try {
			const data = this.collectData(3, true);
			this.metaInfo.showPreloader(true);
			await this.userModel.saveLocalAndSendWord(data);
			this.metaInfo.showPreloader(false);

			// todo open when add random learning mode
			// this.nextWord();

			return this.router.navigate(
				['sets', this.listName],
				{ queryParams: {
						w: this.word,
						p: this.currentPage
					} }
			);
		} catch (e) {
			this.metaInfo.showError(e);
		}
	}

	async saveToLearn () {
		let canSave = false;

		if (
			!!this.words[this.index].text
			&& this.words[this.index].transcription
			&& this.words[this.index].translate
			&& this.words[this.index].examples
		) {
			canSave = true;
		}

		if (canSave) {
			try {
				const data = this.collectData(1, true);
				this.metaInfo.showPreloader(true);
				await this.userModel.saveLocalAndSendWord(data);
				this.metaInfo.showPreloader(false);

				// todo open when add random learning mode
				// this.nextWord();

				return this.router.navigate(
					['sets', this.listName],
					{ queryParams: {
							w: this.word,
							p: this.currentPage
						} }
				);
			} catch (e) {
				this.metaInfo.showError(e);
			}
		} else {
			return this.router.navigate(
				['sets', this.listName, 'word', this.words[this.index].text, 'edit'],
				{ queryParams: {
					search: this.words[this.index].text,
					backto: `sets/${this.listName}`,
					w: this.word,
					p: this.currentPage
				} }
			);
		}
	}

	nextWord () {
		this.index++;
		this.flip = false;
		if (this.words[this.index]) {
			this.saySmt(this.words[this.index].text);
		} else {
			this._finish = true;
		}
	}

	collectData (status?: number, safeAsDraft?: boolean) {
		const data = {
			id: this.words[this.index].id,
			text: this.words[this.index].text,
			transcription: this.words[this.index].transcription,
			translate: this.words[this.index].translate,
			allExamples: this.words[this.index].examples.map((el, i) => {
				if (i >= 5) {
					el.notUsed = true;
				}

				return el;
			}),
			examples: this.words[this.index].examples.filter((el, i) => i < 5),
			status,
			draft: safeAsDraft,
		};

		return data;
	}
}
