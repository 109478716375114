import { Injectable } from '@angular/core';

import { StorageService } from './storage.service';

@Injectable()
export class SpeakerService {
	constructor (
		private storage: StorageService,
	) {
		this.synth = window?.speechSynthesis;

		let loadVoices = async () => {
			if (this.voices.length) {
				return;
			}

			let systemVoices = this.synth.getVoices();

			if (!systemVoices || !systemVoices.length) {
				console.log(`NO ONE VOICE`);
				return;
			}

			systemVoices.forEach(v => {
				if (v.lang.startsWith('en')) {
					this.voices.push(v);
				}
			});

			console.log(`english voices: ${this.voices.length}`);

			this.getDefaultVoice();
		};

		if (this.synth) {
			console.log('has speaker');

			// in Google Chrome the voices are not ready on page load
			if ('onvoiceschanged' in this.synth) {
				this.synth.onvoiceschanged = loadVoices;
			}

			// hack to start faster
			loadVoices();
		} else {
			console.log('=-=-=-=- NEVIZHU SPEACKERA');
		}
	}

	synth = null;
	res = null;
	rej = null;
	voices = [];
	voice: Promise<SpeechSynthesisVoice> = new Promise((_res, _rej) => {
		this.res = _res;
		this.rej = _rej;
	});
	previousVoice = null;
	setVoicePath = 'settings_voice';

	async saySmt (text: string) {
		console.log(`start saySmt, use voice: ${text}`);

		const utterance = new SpeechSynthesisUtterance(text);

		let voice = await this.voice;

		if (this.voice) {
			utterance.voice = voice;
			utterance.lang = voice.lang || 'en';
			console.log('use voice: ', utterance.voice.name);
		}

		utterance.onstart = event => { // SpeechSynthesisEvent
			// utterance.voice {"isTrusted":true}
			// console.log('utterance.voice', JSON.stringify(event));
		};

		this.synth.speak(utterance);
	}

	async changeVoice (voiceName: string) {
		console.log(`changeVoice: ${voiceName}`);
		await this.voice;

		this.voice = this.voices.find(v => v.name === voiceName);
		if (this.voice) {
			this.previousVoice = await this.storage.set(this.setVoicePath, voiceName);
		} else {
			console.log(`changeVoice:NOT FOUND ${this.voices}`);
		}
	}

	async getDefaultVoice () {
		let voice;
		this.previousVoice = await this.storage.get(this.setVoicePath);
		if (this.previousVoice) {
			voice = this.voices.find(v => v.name === this.previousVoice);
		}

		if (!voice) {
			voice = this.voices[0];
		}

		this.setDefaultVoice(voice);
	}

	setDefaultVoice (voice) {
		return this.res(voice);
	}

	getVoice () {
		return this.voice;
	}

	async getVoiceList () {
		await this.voice;

		return this.voices;
	}
}
