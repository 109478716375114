import { Injectable } from '@angular/core';

@Injectable({
	providedIn: 'root',
})
export class StorageService {

	async get (name: string): Promise<any | null> {
		try {
			let valueStr = window.localStorage.getItem(name);

			return JSON.parse(valueStr);
		} catch (e) {
			await this.del(name);
			throw e;
		}
	}

	async set (name: string, data: any): Promise<boolean> {
		try {
			window.localStorage.setItem(name, JSON.stringify(data));

			return true;
		} catch (e) {
			await this.del(name);
			throw e;
		}
	}

	async del (name: string): Promise<boolean> {
		window.localStorage.removeItem(name);

		return true;
	}

	async clearAll (): Promise<boolean> {
		window.localStorage.clear();

		return true;
	}
}
