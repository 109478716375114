<mat-card class="float-card main-card">
	<mat-card-content *ngIf="offline">
		<mat-card-header class="title-card">
			<mat-card-title class="large-text">Даний функціонал працює тільки в режімі онлайн</mat-card-title>
		</mat-card-header>
		<mat-divider></mat-divider>
		<div class="text-center" style="margin: 16px;">
			<mat-icon class="big-icon">wifi_off</mat-icon>
		</div>
	</mat-card-content>
	<mat-card-content *ngIf="words && words?.length"
					  class="card-without-actions"
					  appScrollable
					  infiniteScroll
					  [infiniteScrollDistance]="2"
					  [infiniteScrollUpDistance]="1.5"
					  [infiniteScrollThrottle]="50"
					  (scrolled)="onScrollDown()"
					  (scrolledUp)="onScrollUp()"
					  [scrollWindow]="false"
	>
		<div>
			<mat-selection-list>
				<div class="both-button-row">
					<mat-list-item appOffsetTop *ngFor="let word of words; let i = index;"
								   class="border" [ngClass]="word ? '' : 'app-warning'"
								   routerLink="word/{{word.text}}"
								   [queryParams]="{ p: page }"
					>
						<mat-icon matListItemIcon>{{ getIcon(i) }}</mat-icon>
						<div class="text-en" matListItemTitle style="font-weight: bolder">
							<span>{{word.text}}</span>
						</div>
					</mat-list-item>
				</div>
			</mat-selection-list>
		</div>
	</mat-card-content>
	<mat-card-footer>
	</mat-card-footer>
</mat-card>
