import { Component, ElementRef, HostListener, OnInit, ViewChild } from '@angular/core';
import { EventManager } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';

import { environment } from '../../../environments/environment';
import { UserModelService } from '../../common/models/user-model.service';
import { MetaInfoService } from '../../common/services/meta-info.service';
import { SpeakerService } from '../../common/services/speaker.service';
import { StorageService } from '../../common/services/storage.service';

@Component({
	selector: 'app-study',
	templateUrl: './study.component.html',
	styleUrls: ['./study.component.css']
})
export class StudyComponent implements OnInit {
	constructor (
		public  metaInfo: MetaInfoService,
		private currentRout: ActivatedRoute,
		private router: Router,
		private userModel: UserModelService,
		private eventManager: EventManager,
		private speaker: SpeakerService,
		private storage: StorageService
	) { }

	@ViewChild('engInput') engInput: ElementRef;

	WORD_COUNT = 5;

	step = 0;
	index = 0;
	words;
	studySet = [];
	answersSet = [];
	currentSet = [];
	result = [];
	mistakes = [];
	letters = [];
	randomLetters = [];
	showWord = '';
	typedWord = '';
	wordsOnStudy;
	refToSayWord;
	spareWords;
	audioCrash;
	audioSuccess;
	title = 'Вчити нові слова';
	disabledDKButton = false;
	vibrationClick = 30;
	vibrationFalse = [30, 80, 40];

	@HostListener('document:keydown', ['$event'])
	handleDeleteKeyboardEvent (event: KeyboardEvent) {
		switch (event.key) {
			case 'ArrowRight':
				this.vibrate(this.vibrationClick);
				if (this.step === 0) {
					if (this.words.length < 5) {
						this.router.navigate(['add']);
					} else {
						this.addToStudySet();
					}
				}
				if (this.step === 4) {
					this.checkAnswer4();
				}
				if (this.step === 5) {
					this.reset();
				}
				break;
			case 'ArrowLeft':
				this.vibrate(this.vibrationClick);
				if (this.step === 0) {
					if (this.words.length < 5) {
						this.router.navigate(['dash']);
					} else {
						this.markAsKnown(this.words[this.index]);
					}
				}
				if ([1, 2, 3, 4].includes(this.step)) {
					this.dontKnowWord();
				}
				if (this.step === 5) {
					this.router.navigate(['dash']);
				}
				break;
			case '1':
			case '2':
			case '3':
			case '4':
			case '5':
				if ([1, 2].includes(this.step)) {
					this.checkAnswer(Number(event.key) - 1);
				}
				break;
			default:
				if (3 === this.step) {
					this.keyboardChecker(event.key);
				}
				break;
		}
	}

	async ngOnInit (): Promise<void> {
		try {
			this.metaInfo.setData({
				leftMenu: null,
				title: this.title,
			});

			this.audioCrash = new Audio(environment.frontUrl + '/assets/sounds/crash.mp3');
			this.audioSuccess = new Audio(environment.frontUrl + '/assets/sounds/success.mp3');

			await this.getWordsNewSet();
		} catch (e) {
			console.log('study error', e);
		}
	}

	async getWordsNewSet () {
		let currentWord;
		this.metaInfo.showPreloader(true);

		const wordsString = await this.storage.get('studySet');

		if (wordsString) {
			const wordTexts = wordsString.split('|');

			for (const wordText of wordTexts) {
				const word = await this.userModel.getLocalWordByText(wordText);

				if (word.status === 1) {
					const convertedWord = this.userModel.convertWordToStudy(word);
					this.studySet.push(convertedWord);
				}
			}

			this.index = this.studySet.length > (this.WORD_COUNT - 1) ? this.WORD_COUNT - 1 : this.studySet.length;
		}

		const currentWordText = await this.storage.get('currentWord');
		if (currentWordText) {
			const word = await this.userModel.getLocalWordByText(currentWordText);

			if (word.status === 1) {
				currentWord = this.userModel.convertWordToStudy(word);
			}
			await this.storage.del('currentWord');
		}

		try {
			this.words = await this.userModel.getLocalStudyWords();
			if (wordsString || currentWord) {
				const originalWords = this.words;
				this.words = [...this.studySet];

				const wordTexts = this.studySet.map(w => w.text);
				if (currentWord) {
					this.words.push(currentWord);
					wordTexts.push(currentWord.text);
				}
				const filteredWords = originalWords.filter(w => !wordTexts.includes(w.text));
				this.words = this.words.concat(filteredWords);
				this.words = this.words.slice(0, 5);
			}

			this.metaInfo.showPreloader(false);

			this.startRound(this.step);

			if (this.step === 0) {
				this.metaInfo.setData({
					leftMenu: null,
					rightMenu: {
						icon: 'edit',
						href: `/dictionary/${this.words[this.index].text}/edit`,
						queryParams: { backto: 'study' }
					},
					title: this.title,
				});
				await this.storage.set('currentWord', this.words[this.index].text);
			}

		} catch (err) {
			this.errorHandler(err);
		}
	}

	async addToStudySet () {
		this.vibrate(this.vibrationClick);
		const word = this.words[this.index];

		this.studySet.push(word);
		await this.storage.del('currentWord');

		if (this.index >= (this.words.length - 1)) {
			await this.storage.del('studySet');
			this.nextStep();

			return;
		}

		this.storage.set('studySet', this.studySet.map(w => w.text).join('|'));
		this.nextWord();

		const newWord = this.words[this.index];
		await this.storage.set('currentWord', newWord.text);

		this.metaInfo.setData({
			leftMenu: null,
			rightMenu: {
				icon: 'edit',
				href: `/dictionary/${newWord.text}/edit`,
				queryParams: { backto: 'study' }
			},
			title: this.title,
		});

		await this.sayWithDelay(this.words[this.index].text);
	}

	nextWord () {
		this.index++;
	}

	next () {
		if (this.index >= (this.currentSet.length - 1)) {
			this.nextStep();

			return false;
		}
		this.nextWord();

		return true;
	}

	nextStep () {
		switch (this.step) {
			case 0:
			case 1:
				this.metaInfo.setData({
					rightMenu: null,
					title: this.title,
				});
			case 2:
			case 3:

				const lastWordIndex = this.currentSet
					.findIndex(i => i.text === this.currentSet[this.currentSet.length - 1].text);

				this.studySet = this.shuffleArray(this.studySet, lastWordIndex).map(word => {
					word.check = null;

					return word;
				});
				this.step++;
				this.index = 0;

				this.currentSet = [...this.studySet];

				console.log('studySet', this.studySet);

				this.startRound(this.step);
				break;
			case 4:
				for (let i = 0; i < this.result.length; i++) {
					this.mistakes.push(...Object.keys(this.result[i] || { }));
				}

				const studiedWords = [];
				const failedWords = [];

				for (let i = 0; i < this.words.length; i++) {
					this.words[i].check = { };

					if (this.mistakes.includes(this.words[i].text)) {
						failedWords.push(this.words[i]);
					} else {
						this.words[i].check.status = true;
						studiedWords.push(this.words[i]);
					}
				}

				this.step++;

				if (failedWords.length) {
					this.userModel.saveLocalFailedWords(failedWords);
				}

				if (studiedWords.length) {
					this.userModel.saveLocalStudiedWords(studiedWords);
				}
				break;
		}

		console.log('studySet', this.studySet);
	}

	async markAsKnown (word) {
		this.vibrate(this.vibrationClick);
		try {
			await this.userModel.markAsKnownLocalWord(word);
			const wordTexts = this.words.map(w => w.text);
			let finished = false;

			do {
				if (!this.spareWords?.length) {
					this.spareWords = await this.userModel.getLocalStudyWords();
				}

				const wordCandidate = this.spareWords.shift();

				if (!wordTexts.includes(wordCandidate.text)) {
					this.words = this.words.filter(w => w.text !== word.text);
					this.words.push(wordCandidate);
					this.sayWithDelay(this.words[this.index].text);
					finished = true;
				}
			} while (!finished);
		} catch (e) {
			this.errorHandler(e);
		}
	}

	startRound (step?: any) {
		switch (step) {
			case 0:
				this.sayWithDelay(this.words[this.index].text);
				break;
			case 1:
				this.answersSet = this.shuffleArray(this.studySet);
				this.sayWithDelay(this.currentSet[this.index].text);
				break;
			case 2:
				this.answersSet = this.shuffleArray(this.studySet);
				break;
			case 3:
				this.letters = this.getLetters(this.studySet[this.index].text);
				this.randomLetters = this.shuffleArray(this.letters);
				this.saySmt(this.currentSet[this.index].text);
				break;
			case 4:
				setTimeout(() => {
					this.engInput.nativeElement.focus();
				}, 0);
				break;
			default:
		}
	}

	async sayWithDelay (word, delay = 200) {
		if (this.refToSayWord) {
			clearTimeout(this.refToSayWord);
		}

		await new Promise(res => {
			this.refToSayWord = setTimeout(async () => {
				this.saySmt(word);
				res(null);
			}, delay);
		});
	}

	saySmt (text) {
		this.speaker.saySmt(text);
	}

	shuffleArray (words: Array<Object>, notStartFromIt?: number) {
		let randomIndex;
		let ignoreIndex = notStartFromIt;
		let startArr     = [...words];
		let returnArr    = [];

		while (startArr.length) {
			// the magic for avoid getting of the last array element as the first element in the new shuffled array
			if (ignoreIndex !== undefined) {
				do {
					randomIndex = Math.floor(Math.random() * startArr.length);
				} while (ignoreIndex === randomIndex);
				ignoreIndex = undefined;
			} else {
				randomIndex = Math.floor(Math.random() * startArr.length);
			}
			let [elm] = startArr.splice(randomIndex, 1);
			returnArr.push(elm);
		}

		return returnArr;
	}

	checkAnswer (index) {
		this.vibrate(this.vibrationClick);
		const origWord   = this.currentSet[this.index];
		const answerWord = this.answersSet[index];

		if (origWord.wordId === answerWord.wordId) {
			// this.audioSuccess.play();

			origWord.check = {
				status: true,
				class: 'accent',
				icon: 'check_circle',
				index
			};

			if (this.step === 2 || this.step === 1) {
				this.saySmt(origWord.text);
			}
		} else {
			this.audioCrash.play();

			origWord.check = {
				status: false,
				class: 'warn',
				icon: 'cancel',
				index
			};

			if (this.markAsMistake(origWord)) {
				this.currentSet.push({ ...origWord, check: null });
			}
		}

		setTimeout(() => {
			if (this.next()) {
				this.answersSet = this.shuffleArray(this.answersSet);

				if (this.step === 1) {
					this.sayWithDelay(this.currentSet[this.index].text);
				}
			}
		}, 1000);
	}

	checkAnswer4 () {
		this.vibrate(this.vibrationClick);
		if (this.typedWord.toLowerCase() === this.currentSet[this.index].text.toLowerCase()) {
			this.showWord = 'show';
			this.saySmt(this.currentSet[this.index].text);

			setTimeout(() => {
				this.showWord = '';
				this.typedWord = '';
				if (this.next()) {
					setTimeout(() => {
						this.engInput.nativeElement.focus();
					}, 10);
				}
			}, 1500);
		} else {
			this.audioCrash.play();
			this.showWord = '';
			this.typedWord = '';
			if (this.markAsMistake(this.currentSet[this.index])) {
				this.currentSet.push({ ...this.currentSet[this.index], check: null });
				this.next();
			} else {
				this.typedWord = this.currentSet[this.index].text;
				this.checkAnswer4();
			}
		}
	}

	getLetters (text) {
		let letters = [];
		for (let i = 0; i < (text || '').length; i++) {
			if (/^[a-z]$/i.test(text[i])) {
				letters.push({ text: text[i] });
			}
		}

		return letters;
	}

	getText (text, index) {
		let textPart = '';
		let finish = false;
		for (let i = 0; i < text.length && !finish; i++) {
			if (i >= index && /^[a-z]$/i.test(text[i])) {
				finish = true;
			}
			textPart += text[i];
		}

		return textPart;
	}

	addLetter (index) {
		this.vibrate(this.vibrationClick);
		const correctLetter = this.letters[this.getLetters(this.currentSet[this.index]?.check?.userText).length];

		if (correctLetter.text.toLowerCase() === this.randomLetters[index].text.toLowerCase()) {
			if (!this.currentSet[this.index].check) {
				this.currentSet[this.index].check = { };
			}

			this.currentSet[this.index].check.userText = this.getText(this.currentSet[this.index].text, (this.currentSet[this.index].check.userText || '').length);

			this.randomLetters[index].dissabled = true;

			if (this.currentSet[this.index].check.userText === this.currentSet[this.index].text) {
				this.showWord = 'show';
				this.saySmt(this.currentSet[this.index].text);

				setTimeout(() => {
					this.showNextWord();
				}, 1500);
			}
		} else {
			this.audioCrash.play();
			let letter = this.randomLetters[index];
			letter.color = 'warn';
			this.markAsMistake(this.currentSet[this.index]);

			if ((correctLetter.check ? correctLetter.check += 1 : correctLetter.check = 1) >= 3) {
				for (let i = 0; i < this.randomLetters.length; i++) {
					if (this.randomLetters[i].text === correctLetter.text && !this.randomLetters[i].dissabled) {
						this.addLetter(i);
						break;
					}
				}
			}
			setTimeout(() => {
				letter.color = '';
			}, 500);
		}
	}

	keyboardChecker (key) {
		// todo df add converter to english keyboard
		for (let i = 0; i < this.randomLetters.length; i++) {
			if (this.randomLetters[i].text === key && !this.randomLetters[i].dissabled) {
				return this.addLetter(i);
			}
		}
	}

	markAsMistake (word) {
		if (!this.result[this.step]) {
			this.result[this.step] = { };
		}

		this.result[this.step][word.text] = this.result[this.step][word.text] ? this.result[this.step][word.text] += 1 : 1;

		return this.result[this.step][word.text] < 3;
	}

	showNextWord () {
		this.showWord = '';
		if (this.next()) {
			this.letters = this.getLetters(this.studySet[this.index].text);
			this.randomLetters = this.shuffleArray(this.letters);
			this.saySmt(this.studySet[this.index].text);
		}
	}

	async reset () {
		this.vibrate(this.vibrationClick);
		await this.getWordsNewSet();
		this.step = 0;
		this.index = 0;
		this.studySet = [];
		this.result = [];
	}

	dontKnowWord () {
		this.vibrate(this.vibrationFalse);
		this.disabledDKButton = true;
		let index;

		for (let i = 0; i < this.answersSet.length; i++) {
			if (this.answersSet[i].wordId === this.currentSet[this.index].wordId) {
				index = i;
				break;
			}
		}

		this.saySmt(this.currentSet[this.index].text);

		this.currentSet[this.index].check = {
			status: true,
			class: 'accent',
			icon: 'check_circle',
			userText: this.currentSet[this.index].text,
			index
		};

		this.markAsMistake(this.currentSet[this.index]);

		if (this.step === 4) {
			this.showWord = 'show';
			this.typedWord = '';
			setTimeout(() => {
				this.showWord = '';
				this.next();
				this.disabledDKButton = false;
			}, 1500);

		} else if (this.step === 3) {
			this.showWord = 'show';
			setTimeout(() => {
				this.showNextWord();
				this.disabledDKButton = false;
			}, 1500);
		} else {
			setTimeout(async () => {
				if (this.next()) {
					this.answersSet = this.shuffleArray(this.answersSet);

					if (this.step === 1) {
						await this.sayWithDelay(this.currentSet[this.index].text);
					}
				}
				this.disabledDKButton = false;
			}, 1000);
		}
	}

	errorHandler (err) {
		if (err?.error?.data?.error?.code === 400 && err?.error?.data?.error?.details?.subcode === 1) {
			this.words = [];
			this.wordsOnStudy = err?.error?.data?.error?.details?.count;
			this.metaInfo.showPreloader(false);
		} else {
			this.metaInfo.showError(err);
		}
	}

	edit (word) {
		this.storage.set('currentWord', word.text);

		this.router.navigate(['dictionary', word.text, 'edit'], { queryParams: { backto: 'study' }});
	}


	vibrate (pattern: number | Array<number>): void {
		if (window.navigator.vibrate) {
			window.navigator.vibrate(pattern);
		}
	}
}
