import { Component, OnInit } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';

import { AuthService } from '../../common/services/auth.service';
import { MetaInfoService } from '../../common/services/meta-info.service';

@Component({
	selector: 'app-auth-new-pass',
	templateUrl: './auth-new-pass.component.html',
	styleUrls: ['./auth-new-pass.component.css'],
})
export class AuthNewPassComponent implements OnInit {

	form: UntypedFormGroup;
	showFinish = false;
	redirectTime = 5;
	interval = 1000;

	title = 'Встановоення нового паролю';

	constructor (
		private auth: AuthService,
		private currentRout: ActivatedRoute,
		private router: Router,
		private metaInfo: MetaInfoService,
	) {
	}

	ngOnInit () {

		this.metaInfo.setData({
			leftMenu: null,
			rightMenu: null,
			title: this.title,
		});
		// this.metaInfo.setTitle('');

		this.form = new UntypedFormGroup({
			pass: new UntypedFormControl('', [
				Validators.required,
				Validators.minLength(6),
			]),
		});
	}

	onSubmit () {

		this.metaInfo.showPreloader(true);

		this.auth.newPass({ pass: this.form.value.pass, code: this.currentRout.snapshot.params.code })
		.then(() => {
			this.showFinish = true;

			this.metaInfo.showPreloader(false);

			this.interval = setInterval(() => {
				this.handler();
			}, 1000);
		})
		.catch((e) => {
			this.metaInfo.showPreloader(false);
			console.log('reset-log-err', e);
		});
	}

	handler () {
		if (this.redirectTime > 1) {
			this.redirectTime = this.redirectTime - 1;
		} else {
			clearInterval(this.interval);
			this.router.navigate(['login']);
		}
	}
}
