import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';

import { MatDialog } from '@angular/material/dialog';
import { DomSanitizer } from '@angular/platform-browser';
import { environment as config } from '../../../environments/environment';
import { DialogComponent } from '../../common/components/dialog/dialog.component';
import { UserModelService } from '../../common/models/user-model.service';
import { AuthService } from '../../common/services/auth.service';
import { MetaInfoService } from '../../common/services/meta-info.service';
import { PostMessageService } from '../../common/services/post-message.service';
import { RequesterService } from '../../common/services/requester.service';

@Component({
	selector: 'app-auth-login',
	templateUrl: './auth-login.component.html',
	styleUrls: ['./auth-login.component.css'],
})
export class AuthLoginComponent implements OnInit {
	constructor (
		private auth: AuthService,
		public  dialog: MatDialog,
		private router: Router,
		private requester: RequesterService,
		private user: UserModelService,
		private metaInfo: MetaInfoService,
		private postMessage: PostMessageService,
		private sanitizer: DomSanitizer,
	) {

		this.frameSRC = this.sanitizer.bypassSecurityTrustResourceUrl(config.host + '/social-login');

		this.postMessage.message.subscribe(data => {
			console.log('=-=--=post прилетів');
			switch (data.type) {
				case 'tokens':
					this.auth.saveToken(data.data)
					.then(data => {
						this.router.navigate([config.startURL]);
					});
					break;
				case 'preloader':
					if (data.data) {
						this.metaInfo.showPreloader(true);
					} else {
						this.metaInfo.showPreloader(false);
					}
					break;
			}
		});
	}

	private title = 'Вхід';
	disableButton = false;
	showForm = false;
	showSpinner = true;
	showSocialLogin = true;

	password;
	email;

	frameSRC;
	showFrame;

	form: UntypedFormGroup;

	@ViewChild('iframe') iframe: ElementRef;

	ngOnInit () {
		if (window.location.hostname !== config.originHostName) {
			this.showSocialLogin = false;
		}

		if (this.showSocialLogin) {
			setTimeout(() => {
				this.iframe.nativeElement.onload = () => {
					this.showSpinner = false;
					this.iframe.nativeElement.style.width = '100%';
					this.iframe.nativeElement.style.height = '50px';
				};
			}, 100);
		}

		this.metaInfo.setData({
			leftMenu: null,
			rightMenu: null,
			title: this.title,
		});

		this.form = new UntypedFormGroup({
			email: new UntypedFormControl('', [
				Validators.required,
				Validators.email,
			]),
			password: new UntypedFormControl('', [
				Validators.required,
				Validators.minLength(6),
			]),
			extra: new UntypedFormControl('checked'),
		});



		return this.requester.refreshToken()
		.then(() => {

			this.metaInfo.showPreloader(false);

			// юзер залогінений
			return this.router.navigate(['user-info']);
		})
		.catch(err => {
			this.metaInfo.showPreloader(false);

			// даєм логіниця
		});
	}

	onSubmit () {

		this.disableButton = true;
		this.metaInfo.showPreloader(true);

		this.auth.login({
			email: this.form.value.email,
			password: this.form.value.password,
			extra: this.form.value.extra,
		})
		.then(() => {
			return this.requester.getUser();
		})
		.then(user => {

			this.disableButton = false;
			this.user.createUser(user);
			this.goHome();
		})
		.catch(e => {
			this.metaInfo.showPreloader(false);
			this.disableButton = false;

			if (e?.status === 401) {
				const dialogRef = this.dialog.open(DialogComponent, {
					width: '450px',
					data: {
						title: 'Неправильний логін чи пароль',
						rightBtnTitle: 'Ok',
					}
				});
			} else {
				this.metaInfo.showError(e);
			}

			// dialogRef.afterClosed().subscribe(async result => {
			// 	// console.log('The dialog was closed', result);
			//
			// 	if (result && result.type === 'rightClick') {
			//
			// 	}
			// });
		});
	}

	goHome () {
		return this.router.navigate(['dash']);
	}
}
