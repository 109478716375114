<mat-card class="float-card">
	<mat-card-title>Підтвердження email</mat-card-title>
	<mat-card-content>
	<div *ngIf="result" class="col s12">
		<h3 class="{{result.type ? 'green-text' : 'red-text'}}">{{ result.message }}</h3>
		<div *ngIf="!result.type">Спробуйте увійти чи зареєструватись.</div>
		<div *ngIf="result.type">Ви будете перенаправлені на стрінку входу через {{redirectTime}} секунд</div>
	</div>
	</mat-card-content>
	<mat-divider></mat-divider>
	<mat-card-actions>
		<a mat-stroked-button color="primary" routerLink="/registration">
			Реєстрація
		</a>
		<a mat-stroked-button color="primary" routerLink="/login">
			Вхід
		</a>
	</mat-card-actions>
</mat-card>
