<div style="position: relative">
	<button class="closebtn" mat-dialog-close mat-icon-button>
		<mat-icon>cancel</mat-icon>
	</button>
	<h1 mat-dialog-title class="text-center">
		{{data.title}}
	</h1>
	<div mat-dialog-content class="text-center">
		<div *ngIf="data.showInput">
			<mat-form-field>
				<input matInput [(ngModel)]="data.inputValue">
			</mat-form-field>
		</div>
		<div *ngIf="data.desc">
			<p>{{data.desc}}</p>
		</div>
		<div *ngIf="data.example">
			<div class="holder">
				<button class="close-left-btn" mat-icon-button (click)="clean('en')">
					<mat-icon>cleaning_services</mat-icon>
				</button>
				<textarea rows="4" style="width: 100%" [(ngModel)]="data.example.en"></textarea>
			</div>
			<mat-divider></mat-divider>
			<div class="holder">
				<button class="close-left-btn" mat-icon-button (click)="clean('org')">
					<mat-icon>cleaning_services</mat-icon>
				</button>
				<textarea rows="4" style="width: 100%;" [(ngModel)]="data.example.org"></textarea>
			</div>
		</div>
		<div *ngIf="data.html" [innerHTML]="data.html"></div>
	</div>
	<div mat-dialog-actions class="center-block">
		<button *ngIf="data.leftBtnTitle" mat-raised-button color="{{data.leftBtnColor || 'warn'}}" (click)="onLeftClick()">
			<mat-icon *ngIf="data.leftBtnIcon">{{data.leftBtnIcon}}</mat-icon>
			{{data.leftBtnTitle}}
		</button>
		<button *ngIf="data.rightBtnTitle" mat-raised-button color="{{data.rightBtnColor || 'primary'}}" (click)="onRightClick()">
			<mat-icon *ngIf="data.rightBtnIcon">{{data.rightBtnIcon}}</mat-icon>
			{{data.rightBtnTitle}}
		</button>
	</div>
</div>
