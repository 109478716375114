import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { environment } from '../../../environments/environment';
import { DialogComponent } from '../../common/components/dialog/dialog.component';
import { UserModelService } from '../../common/models/user-model.service';
import { MetaInfoService } from '../../common/services/meta-info.service';
import { RequesterService } from '../../common/services/requester.service';

@Component({
	selector: 'app-start-page',
	templateUrl: './start-page.component.html',
	styleUrls: ['./start-page.component.css']
})
export class StartPageComponent implements OnInit {
	constructor (
		public metaInfo: MetaInfoService,
		private requester: RequesterService,
		private dialog: MatDialog,
		private userModel: UserModelService,
	) {
	}

	showDialog: boolean;
	version;
	bacVersion;
	text = '';
	showLogin = true;
	title = 'Про застосунок';

	async ngOnInit () {
		this.version = environment.version;
		this.bacVersion = await this.requester.gevVersion();

		this.metaInfo.setData({
			leftMenu: null,
			rightMenu: null,
			title: this.title,
		});

		try {
			let user = await this.requester.getUser();
			await this.userModel.createUser(user);
			this.showLogin = false;
		} catch (e) {
		}
	}

	checkPass (pass: string) {
		let text = 'Ты не знаешь код!';

		if (/32167/.test(pass)) {
			text = 'НЕ, ЧЕРНЫХ ДРАКОНОВ УЖЕ НЕ ОСТАЛОСЬ!!!';
		}

		const dialogRef = this.dialog.open(DialogComponent, {
			width: '450px',
			data: {
				title: text,
				rightBtnTitle: 'Ну и лaдно',
				leftBtnTitle: 'Ага',
			}
		});

		dialogRef.afterClosed().subscribe(result => {
			if (result && result.type === 'rightClick') {

			}
		});
	}

	reload (window) {
		return window.location.reload();
	};

	// async test () {
	// 	 await this.requester.gevVersion();
	// 	 await this.requester.getBills();
	// 	 await this.requester.getlogin();
	// 	// const data = await this.requester.login({
	// 	// 	hash: '=-=-=-=-',
	// 	// 	localHash: '=-=--=-',
	// 	// 	allHash: '=-=-=-=',
	// 	// 	extra: '',
	// 	// }, {
	// 	// 	withCredentials: true
	// 	// });
	// }
	protected readonly window = window;
}
