<mat-card class="float-card">
	<h1 class="center-block">Wordup</h1>
	<div>
		<mat-card-content>
			<p>
				Ми допоможемо вивчити стільки слів, що вам позаздрять носії...
			</p>
			<p>
				Сервіс для вивчення англійських слів. Додавай нові слова у власний словник, вчи їх за домогою
				продуктивних тренувань, повторюй їх, щоб запамʼятати на завжди.
			</p>
		</mat-card-content>
		<mat-divider></mat-divider>
		<mat-card-actions *ngIf="showLogin" class="center-block">
			<a mat-stroked-button color="primary" routerLink="/registration">
				Реєстрація
			</a>
			<a mat-stroked-button color="primary" routerLink="/login">
				Вхід
			</a>
		</mat-card-actions>
		<mat-card-actions *ngIf="!showLogin" class="center-block">
			<a mat-stroked-button color="primary" routerLink="/dash">
				Почати
			</a>
		</mat-card-actions>
		<!--<mat-divider></mat-divider>-->
		<div class="version-block">
			<span class="inline-block">збірка: {{version}}/{{bacVersion}}</span> &nbsp; <span class="inline-block">разроблено в "Fancy-Four"</span>
		</div>
		<mat-card-actions class="center-block">
			<a mat-flat-button (click)="reload(window)">
				перезавантажити сторінку
			</a>
		</mat-card-actions>
	</div>
</mat-card>
