<mat-card class="float-card main-card" (keyup.enter)="searchButton()">
	<mat-card-header>
		<mat-form-field style="width: 100%">
			<input matInput type="text" placeholder="Пошук слова" class="search-input"
				   [(ngModel)]="searchField" (keyup)="canSearch()" (ngModelChange)="canSearch()">
<!--			<mat-error *ngIf="true">-->
<!--				Пошук можливий тільки по англійським словам-->
<!--			</mat-error>-->
			<button *ngIf="!searchQuery" [disabled]="disabledInput"  mat-icon-button (click)="searchButton()" matSuffix>
				<mat-icon>search</mat-icon>
			</button>
			<button *ngIf="searchQuery" mat-icon-button (click)="cleanSearch()" matSuffix>
				<mat-icon>close</mat-icon>
			</button>
		</mat-form-field>
	</mat-card-header>
	<mat-card-content *ngIf="words && words?.length"
	  class="card-without-actions"
	  appScrollable
	  infiniteScroll
	  [infiniteScrollDistance]="2"
	  [infiniteScrollUpDistance]="1.5"
	  [infiniteScrollThrottle]="50"
	  (scrolled)="onScrollDown()"
	  (scrolledUp)="onScrollUp()"
	  [scrollWindow]="false"
	>
		<div class="filter-block">
			<button mat-icon-button (click)="changeFilter()"><mat-icon>{{filterIcon}}</mat-icon></button>
			<mat-form-field *ngIf="!filterSwitcher" class="sort-field"  style="margin-bottom: -1.25em">
				<mat-label>Сортування</mat-label>
				<mat-select [(value)]="sortField" [disabled]="false" (selectionChange)="filter()">
					<mat-option value="added">Додано</mat-option>
					<mat-option value="updated">Оновлено</mat-option>
				</mat-select>
			</mat-form-field>
			<mat-form-field *ngIf="!filterSwitcher" class="sort-field"  style="margin-bottom: -1.25em">
				<mat-label>Напрям</mat-label>
				<mat-select [(value)]="sortOrder" [disabled]="false" (selectionChange)="filter()">
					<mat-option value="asc">
						<mat-icon>expand_less</mat-icon>
						найстаріші
					</mat-option>
					<mat-option value="desc">
						<mat-icon>expand_more</mat-icon>
						найновіші
					</mat-option>
				</mat-select>
			</mat-form-field>
			<mat-form-field *ngIf="filterSwitcher" class="sort-field-double"  style="margin-bottom: -1.25em">
				<mat-label>Фільр</mat-label>
				<mat-select [(value)]="filterStatus" [disabled]="false" (selectionChange)="filter()">
					<mat-option value="all">
						усі
					</mat-option>
					<mat-option value="new">
						нові
					</mat-option>
					<mat-option value="repeat">
						на вивченні
					</mat-option>
					<mat-option value="done">
						вивчені
					</mat-option>
				</mat-select>
			</mat-form-field>
		</div>
		<div>
			<mat-selection-list>
				<div class="both-button-row">
					<mat-list-item appOffsetTop *ngFor="let word of words; let i = index;"
								   class="border" [ngClass]="word ? '' : 'app-warning'"
								   routerLink="/dictionary/{{word.text}}"
								   [queryParams]="getOptions()"
					>
						<mat-icon matListItemIcon>{{ getIcon(i) }}</mat-icon>
						<div class="text-en" matListItemTitle style="font-weight: bolder">
							<span>{{word.text}}</span>
						</div>
						<p matListItemLine>
							<span style="">{{word.translate}}</span>
						</p>
					</mat-list-item>
				</div>
			</mat-selection-list>
		</div>
	</mat-card-content>
	<mat-card-content *ngIf="words?.length === 0" >
		<mat-card-header class="title-card">
			<mat-card-title *ngIf="!searchQuery" class="large-text">В словнику поки немає слів</mat-card-title>
			<mat-card-title *ngIf="searchQuery" class="large-text">В словнику немає цього слова</mat-card-title>
		</mat-card-header>
		<mat-divider></mat-divider>
		<div class="text-center" style="margin: 16px;">
			<mat-icon class="big-icon">task_alt</mat-icon>
		</div>
		<mat-card-actions class="center-block">
			<button color="accent" routerLink="/add" [queryParams]="{ search: this.searchField, backto: this.router.url }" mat-flat-button>Додати слово в словник</button>
		</mat-card-actions>
	</mat-card-content>
	<mat-card-footer>
	</mat-card-footer>
</mat-card>
