<mat-card *ngIf="!wordText" class="float-card" (keyup.enter)="searchButton()">
	<mat-card-content>
		<mat-form-field class="wild-input">
			<mat-label>{{disabledInput && searchField ? 'Тільки англійські літери' : 'Пошук англійських слів'}}</mat-label>
			<input matInput type="text" [(ngModel)]="searchField"
				   (keyup)="canSearch()" (ngModelChange)="canSearch()">
			<button *ngIf="!searchClicked" [disabled]="disabledInput" matSuffix mat-icon-button aria-label="Пошук" (click)="searchButton()">
				<mat-icon matTooltip="Тільки англійські літери!!!">search</mat-icon>
			</button>
			<a *ngIf="searchClicked" matSuffix mat-icon-button aria-label="Пошук" (click)="cleanForm()" routerLink="/add">
				<mat-icon matTooltip="Очистити пошук">close</mat-icon>
			</a>
		</mat-form-field>
	</mat-card-content>
</mat-card>

<mat-card class="float-card" *ngIf="foundWord">
	<mat-card-header class="title-card clickable center-block" (click)="saySmt(foundWord.text)">
		<mat-card-title class="large-text">{{foundWord.text}} 🔊</mat-card-title>
		<mat-icon *ngIf="foundWord.localSaved" class="bookmark-left" matTooltip="Слово є в словнику">bookmark</mat-icon>
	</mat-card-header>
	<mat-card-content>
		<mat-list>
			<div mat-subheader>Транскрипція</div>
			<mat-list-item class="list-input" *ngIf="!foundWord.transcription">
				<mat-icon matListItemIcon>translate</mat-icon>
<!--				<mat-form-field class="wild-input list-input">-->
<!--					<p matListItemTitle>Транскрипція</p>-->
<!--					<input matInput [(ngModel)]="userTranscription" placeholder="" value="">-->
<!--				</mat-form-field>-->
				<mat-form-field class="wild-input">
					<mat-label>Введіть транскрипцію</mat-label>
					<input matInput type="text" [(ngModel)]="userTranscription" (ngModelChange)="canSearch()">
				</mat-form-field>
			</mat-list-item>
			<mat-list-item *ngIf="foundWord.transcription" class="slim-list">
				<mat-icon matListItemIcon>translate</mat-icon>
				<div matListItemTitle>
					/{{foundWord?.transcription}}/
				</div>
			</mat-list-item>
			<mat-divider></mat-divider>
			<div mat-subheader>Переклад</div>
			<mat-list-item class="clickable slim-list" (click)="translateClick(i)"
				matBadge="{{translate.s}}" matBadgeOverlap="true"
						   *ngFor="let translate of foundWord.translate; let i = index">
				<mat-icon matListItemIcon>{{translate.active ? 'check_circle' : 'add_circle'}}</mat-icon>
				<div matListItemTitle>{{translate.v}}</div>
			</mat-list-item>
			<mat-list-item class="list-input">
				<mat-icon matListItemIcon>{{userTranslate ? 'check_circle' : 'add_circle'}}</mat-icon>
				<mat-form-field class="wild-input list-input">
					<mat-label>Свій переклад</mat-label>
					<input matInput [(ngModel)]="userTranslate" (ngModelChange)="checkUserTranslate()" placeholder="">
				</mat-form-field>
			</mat-list-item>
			<mat-divider></mat-divider>
			<div mat-subheader>Приклади</div>
			<mat-list-item class="normal-list badged-list" matBadge="{{note.s}}" matBadgeOverlap="true"
						   *ngFor="let note of foundWord.examples; let i = index;">
				<mat-icon matListItemIcon class="clickable" (click)="exampleClick(note)">{{note.notUse ? 'do_not_disturb_on' : 'check_circle'}}</mat-icon>
					<p class="clickable" (click)="changeExample(i)" matListItemTitle>{{note.en || '---'}}</p>
					<p class="clickable" (click)="changeExample(i)" matListItemLine>{{note.org || '---'}}</p>
			</mat-list-item>
			<mat-list-item class="list-input text-right">
				<button (click)="addExample()"
						mat-stroked-button aria-label="Додати приклад">
					Додати приклад
				</button>
			</mat-list-item>
		</mat-list>
		<mat-divider></mat-divider>
		<mat-card-actions class="center-block">
			<a mat-button target="_blank"
			   href="https://www.oxfordlearnersdictionaries.com/definition/english/{{foundWord.text.replace(' ', '-')}}">Oxford 🔗</a>
			<a mat-button target="_blank"
			   href="https://dictionary.cambridge.org/dictionary/english/{{foundWord.text.replace(' ', '-')}}">Cambridge 🔗</a>
			<a mat-button target="_blank"
			   href="https://translate.google.com/?sl=en&tl=uk&text={{foundWord.text}}">Google 🔗</a>
		</mat-card-actions>
		<mat-divider></mat-divider>
	</mat-card-content>

	<mat-card-actions class="center-block bottom-block">
		<button *ngIf="this.wordText || !foundWord.localSaved" (click)="saveAsKnown()" mat-raised-button
				[disabled]="!this.isReadyToSave()">
			<span matTooltip="Поля переклад і транскрипція обовʼязкові">
				{{wordText ? 'Відміна' : 'Вже знаю'}}
			</span>
		</button>
		<button *ngIf="this.wordText || !foundWord.localSaved" (click)="saveAsNew()" mat-raised-button
				[disabled]="!this.isReadyToSave()"
				color="primary">
			<span matTooltip="Поля переклад і транскрипція обовʼязкові">
				{{wordText ? 'Зберегти' : 'Вчити'}}
			</span>
		</button>
		<button *ngIf="!this.wordText && foundWord.localSaved" [routerLink]="['/dictionary', searchField]" [queryParams]="{ backto: this.router.url }" mat-raised-button color="primary">
			Перейти до словнику
		</button>

	</mat-card-actions>
</mat-card>
