import { Component, OnInit, QueryList, ViewChild, ViewChildren } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

import { InfiniteScrollDirective } from 'ngx-infinite-scroll';
import { OffsetTopDirective } from '../../common/directives/offset-top.directive';
import { ScrollableDirective } from '../../common/directives/scrollable.directive';
import { UserModelService } from '../../common/models/user-model.service';
import { MetaInfoService } from '../../common/services/meta-info.service';
import { RequesterService } from '../../common/services/requester.service';

@Component({
	selector: 'app-dictionary',
	templateUrl: './dictionary.component.html',
	styleUrls: ['./dictionary.component.css'],
})
export class DictionaryComponent implements OnInit {
	constructor (
		public metaInfo: MetaInfoService,
		private requester: RequesterService,
		private userModel: UserModelService,
		private router: Router,
		private route: ActivatedRoute,
	) {
	}

	@ViewChildren(OffsetTopDirective) listItems: QueryList<OffsetTopDirective>;
	@ViewChild(ScrollableDirective) list: ScrollableDirective;

	words = null;
	searchQuery = null;
	searchField = '';
	page = 0;
	currentPage = null;
	title = 'Словник';
	disabledInput = true;
	sortOrder = 'desc';
	sortField = 'added';
	filterSwitcher = false;
	filterIcon = 'sort';
	filterStatus = 'all';
	currentWord;

	// @ViewChild(InfiniteScrollDirective) infiniteScrollDirective;

	async ngOnInit (): Promise<void> {
		this.searchField = this.route.snapshot.queryParamMap.get('search');
		this.currentPage = parseInt(this.route.snapshot.queryParamMap.get('page'), 10) || null;

		this.metaInfo.setData({
			leftMenu: null,
			rightMenu: null,
			title: this.title,
		});

		this.route.queryParams
		.subscribe(async params => {
			this.currentWord = params.v;

			if (params.search) {
				this.searchQuery = params.search;

				return this.searchWord(params.search);
			}

			if (params.type) {
				this.filterSwitcher = params.type === 'filter';
			}

			if (params.field) {
				this.sortField = params.field;
			}

			if (params.order) {
				this.sortOrder = params.order;
			}

			if (params.filteredBy) {
				this.filterStatus = params.filteredBy;
			}

			if (this.searchField) {
				await this.searchWord(this.searchQuery);
			} else {
				if (this.currentWord) {
					for (let i = 0; i <= this.currentPage; i++) {
						let newWords = await this.userModel.getLocalWords(i, this.getOptions());
						this.words = (this.words || []).concat(newWords);
					}

					setTimeout(() => {
						let q = this.listItems.find((item, i) => {
							return this.currentWord === item.text;
						})?.offsetTop || 0;
						if (q) {
							this.list.scrollTop = q - 100;
						}
					}, 0);

					this.page = this.currentPage;
				} else {
					await this.getWords();
				}
			}
		});
	}

	getIcon (i) {
		switch (this.words[i].status * 1) {
			case 3:
				return 'task_alt';
			case 2:
				return 'arrow_circle_up';
			default:
				return 'radio_button_unchecked';
		}
	}

	async onScrollDown () {
		// console.log('=-=-=-up; down', this.words.length, this.page + 1);
		if (this.page >= this.currentPage) {
			let newWords = await this.userModel.getLocalWords(this.page + 1, this.getOptions());

			if (newWords.length) {
				this.page = this.page + 1;
			}

			this.words = this.words.concat(newWords);
		}
	}

	onScrollUp () {
		// console.log('=-=-=-up; page');
	}

	async searchButton () {
		if (this.disabledInput) {
			return;
		}

		return this.router.navigate(
			[this.route.snapshot.routeConfig.path],
			{ queryParams: { search: this.searchField = this.searchField.trim() } }
		);
	}

	async cleanSearch () {
		this.searchField = '';
		this.searchQuery = '';

		await this.router.navigate(
			[this.route.snapshot.routeConfig.path],
		);

		await this.getWords();
	}

	async searchWord (word) {
		try {
			this.metaInfo.showPreloader(true);
			this.words = await this.userModel.searchInDictionary(word);
			this.metaInfo.showPreloader(false);
		} catch (e) {
			this.metaInfo.showError(e);
		}
	}

	canSearch () {
		this.disabledInput = /[\u0401\u0451\u0410-\u044fєїіґ]/ig.test(this.searchField) || !(this.searchField?.length > 1);
	}
	async getWords () {
		this.metaInfo.showPreloader(true);
		try {
			this.words = await this.userModel.getLocalWords(0, this.getOptions());
		} catch (e) {
			this.metaInfo.showError(e);
		}
		this.metaInfo.showPreloader(false);
	}

	async filter () {
		this.page  = 0;
		let option = this.getOptions();
		let query  = {
			...option
		};

		// changes the route without moving from the current view or
		// triggering a navigation event,
		await this.router.navigate([], {
			relativeTo: this.route,
			queryParams: query,
			// queryParamsHandling: 'merge', // preserve the existing query params in the route
			// skipLocationChange: true // do not trigger navigation, (not works)
		});

		// can be convenient in case of wrong scroll
		// this.infiniteScrollDirective.disposeScroller.unsubscribe();
		// this.infiniteScrollDirective.setup();

		await this.getWords();
	}

	changeFilter () {
		this.filterSwitcher = !this.filterSwitcher;

		if (this.filterSwitcher === true) {
			this.filterIcon = 'filter_alt';
		} else {
			this.filterIcon = 'sort';
		}

		this.currentWord = '';
		this.currentPage = 0;

		this.filter();
	}

	getOptions () {
		let options: { type: string, filteredBy?: string, field?: string, order?: string, page: number } = {
			type: this.filterSwitcher ? 'filter' :  'sort',
			page: this.page
		};

		// tslint:disable-next-line:prefer-conditional-expression
		if (this.filterSwitcher) {
			options = { ...options, filteredBy: this.filterStatus };
		} else {
			options = { ...options, field: this.sortField, order: this.sortOrder };
		}

		return options;
	}
}
