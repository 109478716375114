import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router, UrlSerializer } from '@angular/router';

import { DialogComponent } from '../../common/components/dialog/dialog.component';
import { UserModelService } from '../../common/models/user-model.service';
import { MetaInfoService } from '../../common/services/meta-info.service';
import { SpeakerService } from '../../common/services/speaker.service';

@Component({
	selector: 'app-word-card',
	templateUrl: './word-card.component.html',
	styleUrls: ['./word-card.component.css']
})
export class WordCardComponent implements OnInit {
	constructor (
		public  metaInfo: MetaInfoService,
		public  dialog: MatDialog,
		private currentRout: ActivatedRoute,
		private router: Router,
		private userModel: UserModelService,
		private speaker: SpeakerService,
		private urlSerializer: UrlSerializer,
	) {
	}

	word;
	icon;
	iconTitle;
	backToUrl;
	backToQp;
	wordText = this.currentRout.snapshot.params.wordId;
	searchField = { v: this.wordText };

	async ngOnInit (): Promise<void> {
		this.metaInfo.showPreloader(true);

		let backToRaw = this.currentRout.snapshot.queryParamMap.get('backto');

		if (backToRaw) {
			let backToTree = this.urlSerializer.parse(backToRaw);
			this.backToUrl = backToRaw.split('?')[0];
			this.backToQp  = backToTree.queryParams;
		}

		this.currentRout.queryParams.subscribe(params => {
			Object.assign(this.searchField, params);
		});

		this.metaInfo.setData({
			title: 'Словник',
			leftMenu: {
				icon: 'west',
				href: this.backToUrl ? this.backToUrl : '/dictionary',
				queryParams: this.backToUrl ? this.backToQp : this.searchField
			},
			rightMenu: {
				icon: 'edit',
				href: `/dictionary/${this.wordText}/edit`,
				queryParams: { backto: this.router.url }
			}
		});

		try {
			this.word = await this.userModel.getLocalWordByText(this.wordText);
			this.word.transcription = this.word.transcription ? this.word.transcription.split('|')[0] : this.word.transcription;
			this.icon;
			this.iconTitle;

			switch (this.word.status) {
				case 3: {
					this.icon = 'task_alt';
					this.iconTitle = 'Вивчено';
					break;
				}
				case 2: {
					this.icon = 'arrow_circle_up';
					this.iconTitle = 'Наступна перевірка';

					const nextCheck = new Date(this.word.next_check_at);
					const m = nextCheck.getMonth() > 8 ? nextCheck.getMonth() + 1 : ('0' + (nextCheck.getMonth() + 1));
					const d = nextCheck.getDate() > 9 ? nextCheck.getDate() : ('0' + nextCheck.getDate());
					const h = nextCheck.getHours() > 9 ? nextCheck.getHours() : ('0' + nextCheck.getHours());
					const mm = nextCheck.getMinutes() > 9 ? nextCheck.getMinutes() : ('0' + nextCheck.getMinutes());

					this.word.next_check_at = `${nextCheck.getFullYear()}-${m}-${d} ${h}:${mm}`;
					break;
				}
				default: {
					this.icon = 'radio_button_unchecked';
					this.iconTitle = 'Нове';
					break;
				}
			}

			this.metaInfo.showPreloader(false);
		} catch (err) {
			this.metaInfo.showError(err);
		}
	}

	getIcon (i) {
		switch (this.word.status * 1) {
			case 3:
				return 'task_alt';
			case 2:
				return 'outbound';
			default:
				return 'radio_button_unchecked';
		}
	}

	saySmt (text) {
		this.speaker.saySmt(text);
	}

	delWord () {
		const dialogRef = this.dialog.open(DialogComponent, {
			width: '450px',
			data: {
				title: 'Видалити слово?',
				rightBtnTitle: 'Видалити',
				rightBtnColor: 'warn',
				leftBtnTitle: 'Ні, дякую',
				leftBtnColor: 'primary',
			}
		});

		dialogRef.afterClosed().subscribe(async result => {
			if (result && result.type === 'rightClick') {
				try {
					this.metaInfo.showPreloader(true);
					await this.userModel.safeDelWord({
						id: this.word.wordId,
						text: this.word.text,
						version: this.word.version
					});
					this.metaInfo.showPreloader(false);
					await this.router.navigate([this.backToUrl ? this.backToUrl : 'dictionary'], { queryParams: this.backToQp });
				} catch (err) {
					this.metaInfo.showError(err);
				}
			}
		});
	}

	resetWord () {
		const dialogRef = this.dialog.open(DialogComponent, {
			width: '450px',
			data: {
				title: 'Увага!',
				desc: 'Прогрес по вивченю слова буде видалений. Слово доведеться вчити з початку',
				rightBtnTitle: 'Скинути',
				rightBtnColor: 'warn',
				leftBtnTitle: 'Ні, дякую',
				leftBtnColor: 'primary',
			}
		});

		dialogRef.afterClosed().subscribe(async result => {
			if (result && result.type === 'rightClick') {
				try {
					this.metaInfo.showPreloader(true);
					await this.userModel.resetLocalWordAndSend({
						id: this.word.wordId,
						text: this.word.text,
						version: this.word.version
					});
					this.metaInfo.showPreloader(false);
					await this.router.navigate([this.backToUrl ? this.backToUrl : 'dictionary'], { queryParams: this.backToQp });
				} catch (err) {
					this.metaInfo.showError(err);
				}
			}
		});
	}
}
