<mat-card class="float-card">
	<mat-card-header>
		<h2>Повторення слів</h2>
	</mat-card-header>
	<mat-card-content>
		<p>
			Слів на повторення: {{dashData.repeat}} {{dashData.inProgress ? '(' + dashData.inProgress + ')' : ''}}
		</p>
		<mat-divider></mat-divider>
	</mat-card-content>
	<mat-card-actions class="right-block">
		<button mat-stroked-button color="primary" routerLink="/repeat">
			Повторити слова
		</button>
	</mat-card-actions>
</mat-card>
<mat-card class="float-card">
	<mat-card-header>
		<h2>Вчити нові слова</h2>
	</mat-card-header>
	<mat-card-content>
		<p>
			Слів на вивчення: {{dashData.new}}
		</p>
		<mat-divider></mat-divider>
	</mat-card-content>
	<mat-card-actions class="right-block">
		<button mat-stroked-button color="primary" routerLink="/study">
			Вчити слова
		</button>
	</mat-card-actions>
</mat-card>
<mat-card class="float-card">
	<mat-card-header>
		<h2>Словник</h2>
	</mat-card-header>
	<mat-card-content>
		<p>
			Всього слів в словнику:  {{dashData.done}}
		</p>
		<mat-divider></mat-divider>
	</mat-card-content>
	<mat-card-actions class="right-block">
		<a mat-stroked-button color="primary" routerLink="/dictionary">
			Перейти в словник
		</a>
	</mat-card-actions>
</mat-card>
<button class="fixed-action-btn" routerLink="/add" mat-fab color="accent" aria-label="Додати нове слово">
	<mat-icon>add</mat-icon>
</button>
