import { HttpClientModule } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { FlexLayoutModule } from '@angular/flex-layout';
import { FormsModule, ReactiveFormsModule } from '@angular/forms'; // <-- NgModel lives here
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ServiceWorkerModule } from '@angular/service-worker';
import { InfiniteScrollModule } from 'ngx-infinite-scroll';

import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatBadgeModule } from '@angular/material/badge';
import { MatButtonModule } from '@angular/material/button';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { MatCardModule } from '@angular/material/card';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatChipsModule } from '@angular/material/chips';
import { MatNativeDateModule } from '@angular/material/core';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatDialogModule } from '@angular/material/dialog';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatGridListModule } from '@angular/material/grid-list';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatListModule } from '@angular/material/list';
import { MatMenuModule } from '@angular/material/menu';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatRadioModule } from '@angular/material/radio';
import { MatSelectModule } from '@angular/material/select';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatStepperModule } from '@angular/material/stepper';
import { MatTableModule } from '@angular/material/table';
import { MatTabsModule } from '@angular/material/tabs';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatTooltipModule } from '@angular/material/tooltip';

import { environment } from '../../environments/environment';
import { DialogComponent } from '../common/components/dialog/dialog.component';
import { ErrorShowerComponent } from '../common/components/error-shower/error-shower.component';
import { PreloaderComponent } from '../common/components/preloader/preloader.component';
import { AuthConfirmEmailComponent } from './auth-confirm-email/auth-confirm-email.component';
import { AuthLoginComponent } from './auth-login/auth-login.component';
import { AuthNewPassComponent } from './auth-new-pass/auth-new-pass.component';
import { AuthPasswordResetComponent } from './auth-password-reset/auth-password-reset.component';
import { AuthRegistrationComponent } from './auth-registration/auth-registration.component';
import { DashComponent } from './dash/dash.component';
import { DictionaryComponent } from './dictionary/dictionary.component';
import { NotFoundComponent } from './not-found/not-found.component';
import { RepeatComponent } from './repeat/repeat.component';
import { StartPageComponent } from './start-page/start-page.component';
import { StudyComponent } from './study/study.component';
import { SystemRoutingModule } from './system-routing.module';
import { SystemComponent } from './system.component';
import { UnsubscribeComponent } from './unsubscribe/unsubscribe.component';
import { UserInfoComponent } from './user-info/user-info.component';
import { WordAddComponent } from './word-add/word-add.component';
import { WordCardComponent } from './word-card/word-card.component';
import { SetListComponent } from './set-list/set-list.component';

import { UserModelService } from '../common/models/user-model.service';
import { AuthService } from '../common/services/auth.service';
import { RequesterService } from '../common/services/requester.service';
import { SpeakerService } from '../common/services/speaker.service';
import { ListsAddComponent } from './lists-add/lists-add.component';
import { SetsComponent } from './sets/sets.component';
import { TestComponent } from './test/test.component';

import { OffsetTopDirective } from '../common/directives/offset-top.directive';
import { ScrollableDirective } from '../common/directives/scrollable.directive';

@NgModule({
	declarations: [
		SystemComponent,
		StartPageComponent,
		AuthLoginComponent,
		AuthRegistrationComponent,
		NotFoundComponent,
		AuthConfirmEmailComponent,
		UserInfoComponent,
		AuthPasswordResetComponent,
		AuthNewPassComponent,
		UnsubscribeComponent,
		DialogComponent,
		PreloaderComponent,
		ErrorShowerComponent,
		DictionaryComponent,
		WordCardComponent,
		RepeatComponent,
		StudyComponent,
		DashComponent,
		WordAddComponent,
		SetsComponent,
		ListsAddComponent,
		TestComponent,
		ScrollableDirective,
		OffsetTopDirective,
		SetListComponent,
	],
	imports: [
		BrowserModule,
		HttpClientModule,
		BrowserAnimationsModule,
		FormsModule,
		ReactiveFormsModule,
		FlexLayoutModule,
		MatMenuModule,
		MatButtonModule,
		MatCheckboxModule,
		MatToolbarModule,
		MatIconModule,
		MatSelectModule,
		MatSidenavModule,
		MatCardModule,
		MatInputModule,
		MatTableModule,
		MatListModule,
		MatExpansionModule,
		MatDialogModule,
		MatChipsModule,
		MatTabsModule,
		MatTooltipModule,
		MatRadioModule,
		MatProgressBarModule,
		MatProgressSpinnerModule,
		MatDatepickerModule,
		MatNativeDateModule,
		MatSlideToggleModule,
		MatGridListModule,
		MatButtonToggleModule,
		MatStepperModule,
		MatAutocompleteModule,
		ServiceWorkerModule.register('ngsw-worker.js', {enabled: environment.production}),
		SystemRoutingModule,
		MatBadgeModule,
		InfiniteScrollModule
	],
	providers: [
		RequesterService,
		SpeakerService,
		AuthService,
		UserModelService,
	],
	bootstrap: [SystemComponent]
})
export class SystemModule {
}
