import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { DateTime } from 'luxon';
import { environment } from '../../../environments/environment';
import { SystemError } from '../Error';
import { StorageService } from './storage.service';

@Injectable()
export class RequesterService {

	private baseURL = environment.apiUrl;  // URL to web api
	showPreloader = false;

	constructor (
		private http: HttpClient,
		private storage: StorageService,
	) {
	}

	getHeaders (needToken = false): Promise<{ withCredentials: boolean }> {
		return Promise.resolve({ withCredentials: true });
	}

	gevVersion () {
		return this.request('get', `/version`, { });
	}

	/* LOGIN */

	preAuth (data) {
		return this.request('post', `/auth/pre-auth`, data);
	}

	login (data: object, opt?: object) {
		return this.request('post', '/auth/login', data, opt);
	}

	async logout () {
		return this._post('/auth/logout', null, false);
	}

	loginWithSocial (data: { name: string, email: string, photoUr: string, token: string, provider: string }) {
		return this.request('post', '/auth/login/social', data);
	}

	registration (data) {
		return this.request('post', '/auth/registration', data);
	}

	confirmEmail (data) {
		return this.request('post', '/auth/confirm/email', data);
	}

	resetPass (email) {
		return this.request('post', '/auth/reset-pass', { email });
	}

	newPass (data) {
		return this.request('post', '/auth/new-pass', data);
	}

	getUserWords (userId, page = 0) {
		return this._get(`/users/${userId}/words?page=${page}`, true);
	}

	delUserWord (userId, wordId) {
		// return []
		return this._del(`/users/${userId}/words/${wordId}`);
	}

	delUserWordByText (userId, word) {
		return this._del(`/users/${userId}/words/${word.text}/v/${word.version}`);
	}

	resetUserWord (userId, word) {
		return this._patch(`/users/${userId}/words/reset`, { word });
	}

	async getWord (wordText, lang) {
		return this._get(`/words/${wordText}/lang/${lang}`);
	}

	async getUserWordById (userId, wordId, ignoreSW?: boolean) {
		const result = await this._get(`/users/${userId}/words/${wordId}`, ignoreSW);

		return {
			wordId: 					+result.id,
			text: 						result.text,
			transcription: 				result.transcription,
			translate: 					result.translate,
			examples: 					result.examples,
			status: 					+result.status,
			step: 						+result.step,
			version: 					+result.version,
			next_check_at: 				result.next_check_at ? DateTime.fromSQL(result.next_check_at, { zone: 'UTC' }).toMillis() : null,
			added_at: 					DateTime.fromSQL(result.added_at, { zone: 'UTC' }).toMillis(),
			updated_at: 				DateTime.fromSQL(result.updated_at, { zone: 'UTC' }).toMillis(),
		};
	}

	getUserStudyWords (userId) {
		return this._get(`/users/${userId}/study`);
	}

	getUserRepeatWords (userId) {
		return this._get(`/users/${userId}/repeat`);
	}

	saveUserRepeatWords (userId, data) {
		return this._post(`/users/${userId}/repeat`, data);
	}

	saveUserStudyWords (userId, data) {
		return this._post(`/users/${userId}/study`, data);
	}

	searchWord (word, lang) {
		return this._get(`/words/${word}/lang/${lang}`);
	}
	searchWordInUserDictionary (userId: number, word: string) {
		return this._get(`/users/${userId}/words/search/${word}`);
	}

	saveUserWord (userId, data) {
		return this._post(`/users/${userId}/words`, data);
	}

	updateUserWord (userId, word, data) {
		return this._put(`/users/${userId}/words/${word}`, data);
	}

	userWordMarkAsKnown (userId, data = { }) {
		return this._patch(`/users/${userId}/words/mark-as-known`, data);
	}

	getDashData (userId, ignodeSW) {
		return this._get(`/users/${userId}/dash`, ignodeSW);
	}

	getWordsLists (userId, ignoreSW?: boolean) {
		// return this._get(`/users/${userId}/word-sets`, ignoreSW);
		return this._get(`/users/${userId}/words-lists`, ignoreSW);
	}

	getWordListByName (userId, listName, lang, page, ignoreSW?: boolean) {
		return this._get(`/users/${userId}/sets/${listName}/lang/${lang}?page=${page}`, ignoreSW);
	}

	getWordsListById (userId, listId, lang, ignoreSW?: boolean) {
		return this._get(`/users/${userId}/words-lists/${listId}/lang/${lang}`, ignoreSW);
	}

	getSyncList (userId: number, page: number, ignoreSW?: boolean): Promise<Array<any>> {
		return this._get(`/users/${userId}/sync-list/${page}`, ignoreSW);
	}

	getToken (): Promise<any> {
		return Promise.all([
			this.storage.get('token'),
			this.storage.get('tokenExp'),
		])
		.then(([token, expTime]) => {

			if (+expTime > (Date.now() + 10000) && token) {
				return { token };
			}

			throw new Error('Does not have token');
		});
	}

	async refreshToken () {
		return this._post(`/auth/refresh`, null, false);
	}

	/**  USER */

	getUser (): Promise<{ id: number, name: string, email: string, lang: string, notification: boolean }> {
		return this.getHeaders(true)
		.then(opt => {
			return this.request('get', `/user`, opt);
		});
	}

	updateUser (data): Promise<any> {
		return this.getHeaders(true)
		.then(opt => {
			return this.request('put', `/user`, data, opt);
		});
	}

	postUserSub (sub): Promise<void> {
		return this.getHeaders(true)
		.then(opt => {
			return this.request('post', `/user/subscription`, sub, opt);
		});
	}

	delUserSub (sub): Promise<void> {
		return this.getHeaders(true)
		.then(opt => {
			let simpleSub = JSON.parse(JSON.stringify(sub));

			// console.log('=-=-=-simple', simpleSub);

			return this.request('delete', `/user/subscription/${simpleSub.keys.auth}`, opt);
		});
	}

	getTest (): Promise<any> {
		return this.getHeaders()
		.then(opt => {
			return this.request('get', `/test/1`, opt);
		});
	}

	getHeartbeat (ignoreSW?: boolean, timeout?): Promise<any> {
		return  this._get(`/heartbeat`, ignoreSW, timeout);
	}

	_get (url: string, ignoreSW?: boolean | string, timeout?): Promise<any> {
		const opt = { withCredentials: true };
		if (ignoreSW && environment.production) {
			opt['headers'] = {
				'ngsw-bypass': 'true'
			};
		}
		// it is only a custom header, so we need find something else
		// if (timeout) {
		// 	opt['headers'] = {
		// 		'timeout': `${timeout}`
		// 	};
		// }

		return this.request('get', url, opt);
	}

	_del (url: string) {
		const opt = { withCredentials: true };

		return this.request('delete', url, opt);
	}

	_post (url: string, data?, relogin = true) {
		const opt = { withCredentials: true };

		return this.newRequester('post', url, data, opt, relogin);
	}

	_patch (url: string, data?, relogin = true) {
		const opt = { withCredentials: true };

		return this.newRequester('patch', url, data, opt, relogin);
	}

	_put (url: string, data?, relogin = true) {
		const opt = { withCredentials: true };

		return this.newRequester('put', url, data, opt, relogin);
	}

	async request (method, url, second?, third?): Promise<any> {
		try {
			return await this._request(method, url, second, third);
		} catch (err) {
			if (err?.code === 'NEED_AUTH') {
				await this.refreshToken();

				return this._request(method, url, second, third);
			}

			throw err;
		}
	}

	async newRequester (method: string, url: string, data, opt, relogin) {
		try {
			return await this._request(method, url, data, opt);
		} catch (err) {
			if (relogin && err?.code === 'NEED_AUTH') {
				await this.refreshToken();

				return this._request(method, url, data, opt);
			}

			throw err;
		}
	}

	_request (method, url, second?, third?): Promise<any> {
		return new Promise((res, rej) => {
			return this.http[method](`${this.baseURL}${url}`, second, third)
			.subscribe((response: any) => {
				if (response.status) {
					return res(response.data);
				}

				return rej(response);
			}, (err: any) => {
				if (err.status === 401) {
					return rej(new SystemError({
						code: 'NEED_AUTH',
						status: err.status,
					}));
				}

				if (err.status >= 500) {
					return rej(new SystemError({
						code: 'SERVER_ERROR',
						status: err.status,
					}));
				}

				if (err.status === 0) {
					return rej(new SystemError({
						code: 'CONNECTION_ERROR',
						status: err.status,
						originalError: err,
					}));
				}

				return rej(err);
			});
		});
	}

// /**
//  * Handle Http operation that failed.
//  * Let the system continue.
//  * @param operation - name of the operation that failed
//  * @param result - optional value to return as the observable result
//  */
// private handleError (operation = 'operation', result?) {
//   return (error: any): Observable => {
//
//     // TODO: send the error to remote logging infrastructure
//     console.error(error); // log to console instead
//
//     return of(result);
//   };
// }
}
