<mat-card class="float-card text-center">
	<mat-card-content *ngIf="messageError" class="alert">
		<span class="closebtn" (click)="messageError = ''">&times;</span>
		{{ messageError }}
	</mat-card-content>
	<mat-card-content *ngIf="!resReg; else result">
		<form [formGroup]="form" (ngSubmit)="onSubmit()">

			<div>
				<mat-form-field class="first-row">
					<input matInput formControlName="name" maxlength="100" name="name" type="text" placeholder="Name">
				</mat-form-field>
				<p class="title" *ngIf="form.get('name').invalid && form.get('name').touched">
					Поле не меє бути порожнім
				</p>
			</div>
			<div>
				<mat-form-field>
					<input matInput formControlName="email" name="email" type="email" maxlength="100"
						   placeholder="E-mail">
				</mat-form-field>
				<p class="title" *ngIf="form.get('email').invalid && form.get('email').touched">
					Не вірний формат E-mail
				</p>
			</div>
			<div>
				<mat-form-field>
					<input matInput formControlName="password" type="password"
						   name="password" placeholder="Password" maxlength="100">
				</mat-form-field>
				<p class="title" *ngIf="form.get('password').invalid && form.get('password').touched">
					{{ form.get('password')['errors']['required'] ? 'Поле не меє бути порожнім' : '' }}
					{{ form.get('password')['errors']['minlength'] ? 'Поле не меє бути менше ' +
					form.get('password')['errors']['minlength']['requiredLength'] : '' }}
				</p>
			</div>
			<div class="button-row">
				<button mat-raised-button [disabled]="form.invalid" type="submit" color="primary">
					Зберегти
				</button>
			</div>
		</form>
	</mat-card-content>
	<mat-divider></mat-divider>
	<mat-card-actions class="center-block">
		<a mat-stroked-button color="primary" routerLink="/reset-pass">
			Скидання паролю
		</a>
		<a mat-stroked-button color="primary" routerLink="/login" color="primary">
			Вхід
		</a>
	</mat-card-actions>
</mat-card>
<ng-template #result>
	<mat-card-content>
		<h2>Реєстрація пройшла успішно</h2>
		На Вашу пошту відправлено лист, для підтвердження реєстрації перейдіть по посиланю вкладеному в ньому
		<!--через {{redirectTime}} секунд вы будете переадресованы на <a routerLink="/auth-login">страницу входа</a>-->
	</mat-card-content>
</ng-template>
